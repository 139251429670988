import React, { useState, useEffect } from "react";
import { Text, View, FlatList, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Card } from "@rneui/themed";
import Spinner from "react-native-loading-spinner-overlay";
import { format } from "date-fns";
import { Picker } from "@react-native-picker/picker";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const fs = getFirestore(firebaseApp);

export default function UserHistoriesScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const initialState = [];
  const [userHistories, setUserHistories] = useState(initialState);

  const [filter, setFilter] = useState("");

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: null,
    });

    getUserHistories("");

    setLoading(false);
  }, []);

  const getUserHistories = async (filter) => {
    //Show Loading
    setLoading(true);

    // Initialize
    setUserHistories(initialState);

    let userHistoriesDocs = undefined;
    if (filter == "" || filter == "All Histories") {
      // Get all histories
      userHistoriesDocs = await getDocs(
        query(
          collection(fs, "users/" + global.userId + "/histories"),
          orderBy("timestamp", "desc")
        )
      );
    } else {
      setFilter(filter);

      // Get filtered histories
      userHistoriesDocs = await getDocs(
        query(
          collection(fs, "users/" + global.userId + "/histories"),
          where("type", "==", filter),
          orderBy("timestamp", "desc")
        )
      );
    }

    userHistoriesDocs.forEach((userHistory) => {
      const historyData = userHistory.data();
      setUserHistories((current) => [
        ...current,
        {
          id: userHistory.id,
          timestamp: historyData.timestamp,
          metadata: historyData.metadata,
        },
      ]);
      // Hide Loading
      setLoading(false);
    });
    // Hide Loading
    setLoading(false);
  };

  const dateFormat = (value) => {
    return format(value, "MMMM do, yyyy H:mma");
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <Picker
        selectedValue={filter}
        onValueChange={(itemValue, itemIndex) => getUserHistories(itemValue)}
        style={{
          height: 30,
          marginTop: 10,
          width: "90%",
          marginBottom: 10,
        }}
      >
        <Picker.Item label="All Histories" value="All Histories" />
        <Picker.Item label="Updated" value="Updated" />
        <Picker.Item label="Logged In" value="Logged In" />
        <Picker.Item label="Registered" value="Registered" />
        <Picker.Item label="Logged Out" value="Logged Out" />
      </Picker>

      <FlatList
        style={{ width: "100%" }}
        data={userHistories}
        keyExtractor={(userHistories) => userHistories.id}
        onRefresh={() => onRefresh()}
        refreshing={loading}
        renderItem={({ item }) => (
          <Card style={{ width: "90%" }}>
            <Card.Title style={{ color: "#C9060C" }}>
              {dateFormat(item.timestamp.toDate())}
            </Card.Title>
            <Card.Divider />
            <Text style={{ fontSize: 16, color: "#3671C2" }}>
              {item.metadata}
            </Text>
          </Card>
        )}
      />
    </View>
  );
}
