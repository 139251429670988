import React, { useEffect } from "react";
import { Text, View, Animated, Easing } from "react-native";
import { useNavigation } from "@react-navigation/native";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const fs = getFirestore(firebaseApp);

export default function SplashScreen({}) {
  const navigation = useNavigation();

  // Initialize
  global.isPreview = false;
  global.selectedCard = undefined;
  global.selectedCardConfiguration = undefined;

  global.errorCode = undefined;
  global.errorMessage = undefined;

  global.authUserUID = undefined;
  global.userId = undefined;
  global.userEmail = undefined;
  global.userDisplayName = undefined;
  global.userDaysSinceRegistration = 0;
  global.userDaysToVerifyEmail = 14;

  global.cardId = undefined;
  global.uid = undefined;
  global.cardData = undefined;
  global.versionNumber = "PR00.22.00";

  let spinValue = new Animated.Value(0);

  // Set up animation
  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 3000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start()
  );

  // Interpolate beginning and end values (in this case 0 and 1)
  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  useEffect(() => {
    handleURLParma();
  }, []);

  const handleURLParma = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get("tosh");
    const action = urlParams.get("actn");
    const source = urlParams.get("src");
    global.uid = "";

    let goToNext = "";

    //  Check for card id on query string
    if (uid && uid != "") {
      // UID passed on query string ... save it.
      global.uid = uid;

      // Get a reference to the cards collection
      const cardsRef = collection(fs, "cards");

      // Query for the card uid
      const cardQuery = query(
        cardsRef,
        where("uid", "==", uid),
        where("active", "==", true)
      );
      const cardDocs = await getDocs(cardQuery);

      // Make sure we only got one matching card
      if (cardDocs.docs.length == 0 || cardDocs.docs.length > 1) {
        global.errorCode = "1005";
        global.errorMessage =
          "Sorry, but something went wrong. Tap or Scan Here has been notified of your error and will be looking into it.";
        goToNext = "Error";

        // Login as Admin to save the error
        await signInWithEmailAndPassword(
          auth,
          "admin@taporscanhere.com",
          "T0Myj35Rk#t7"
        ).then(async (userCredential) => {
          // Add history to hacker attemtps
          const historyRef = await addDoc(collection(fs, "errors/"), {
            type: "Accessed",
            metadata:
              "Card Accessed ... UID: " +
              uid +
              " / Source: " +
              source +
              " / Action: " +
              action,
            timestamp: new Date(),
          });
          await auth.signOut();
        });
      } else {
        // Save the card found
        cardDocs.forEach((card) => {
          global.cardId = card.id;
          global.cardData = card.data();
        });

        // Login as Admin to save the history
        await signInWithEmailAndPassword(
          auth,
          "admin@taporscanhere.com",
          "T0Myj35Rk#t7"
        ).then(async (userCredential) => {
          // Add history to card
          const historyRef = await addDoc(
            collection(fs, "cards/" + global.cardId + "/histories"),
            {
              type: "Accessed",
              metadata:
                "Card Accessed ... Source: " + source + " / Action: " + action,
              timestamp: new Date(),
            }
          );
          await auth.signOut();
        });
      }

      // Check that we found a card, security check.
      if (global.cardData && global.cardData.uid == global.uid) {
        // Check for a user, to see if the card is already registered.
        if (global.cardData.authUserUID && global.cardData.authUserUID != "") {
          // Check if the card is configured to do something
          const cardConfigurationDocs = await getDocs(
            collection(fs, "cards/" + global.cardId + "/configurations")
          );
          // Check if configirations have been setup
          if (cardConfigurationDocs.docs.length > 0) {
            // Functionality configured, go to launch
            goToNext = "Launch";
          } else {
            // Functionality not configured, go to login
            goToNext = "Login";
          }
        } else {
          // Card not registered yet, go to registration
          goToNext = "Registration";
        }
      } else {
        // Can't find card based on UID, go nowhere.
        global.errorCode = "1001";
        global.errorMessage =
          "Sorry, but something went wrong. Tap or Scan Here has been notified of your error and will be looking into it.";
        goToNext = "Error";
      }
    } else {
      // No card id on the query string, got to login
      goToNext = "Login";
    }

    const splashScreenDelayCounter = setInterval(() => {
      clearInterval(splashScreenDelayCounter);
      if (goToNext != "") {
        // Navigate if defined
        navigation.navigate(goToNext);
      }
    }, 2000);
  };

  return (
    <View style={styles.splashScreenContainer}>
      <Animated.Image
        style={{
          width: 256,
          height: 256,
          marginBottom: 10,
          transform: [{ rotate: spin }],
        }}
        source={require("../../assets/logos/TOSH-Logo-256x256.png")}
      />
      <Text
        style={{
          fontSize: 24,
          fontWeight: "bold",
          color: "#3671c2",
          marginTop: 100,
        }}
      >
        Loading ... Please Wait.
      </Text>
    </View>
  );
}
