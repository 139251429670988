import React, { useState, useEffect } from "react";
import { Image, Text, View, FlatList, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Card } from "@rneui/themed";
import Spinner from "react-native-loading-spinner-overlay";
import { isSafari } from "react-device-detect";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const fs = getFirestore(firebaseApp);

export default function HomeScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const initialState = [];
  const [userRegisteredCards, setUserRegisteredCards] = useState(initialState);

  const [emailVerificationMessage, setEmailVerificationMessage] = useState(
    "Your email hasn't been verified. Please check your email, or resend the email verification now."
  );

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerLeft: () => (
        <TouchableOpacity onPress={() => handleAccountSelect()}>
          <Image
            style={{
              width: 36,
              height: 36,
              marginLeft: 10,
              tintColor: "#69AD4C",
            }}
            source={require("../../assets/material-icons/account_circle.png")}
          />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => handleLogoutSelect()}>
          <Image
            style={{
              width: 36,
              height: 36,
              marginRight: 10,
              tintColor: "#3671C2",
            }}
            source={require("../../assets/material-icons/logout.png")}
          />
        </TouchableOpacity>
      ),
    });

    navigation.addListener("focus", () => {
      getUserRegisteredCards();
    });

    setLoading(false);
  }, []);

  const handleLogoutSelect = async () => {
    // Show loading
    setLoading(true);

    // Log out as the current user
    await auth.signOut();

    // Login as Admin to save the history
    await signInWithEmailAndPassword(
      auth,
      "admin@taporscanhere.com",
      "T0Myj35Rk#t7"
    ).then(async (userCredential) => {
      // Add History to the user
      const userHistoryDoc = await addDoc(
        collection(fs, "users/" + global.userId + "/histories"),
        {
          type: "Logged Out",
          metadata: "Account logged out.",
          timestamp: new Date(),
        }
      );
      await auth.signOut();
    });

    // Hide loading
    setLoading(false);

    navigation.navigate("Login");
  };

  const getUserRegisteredCards = async () => {
    // Show loading
    setLoading(true);

    //Reset
    setUserRegisteredCards(initialState);

    // Get a reference to the cards collection
    const cardsRefs = collection(fs, "cards");

    // Query for the card uid
    const cardQuery = query(
      cardsRefs,
      where("authUserUID", "==", global.authUserUID)
    );
    const cardDocs = await getDocs(cardQuery);

    cardDocs.forEach(async (card) => {
      const cardData = card.data();
      // Get number of configurations
      const cardConfigurationDocs = await getDocs(
        collection(fs, "cards/" + card.id + "/configurations")
      );
      setUserRegisteredCards((current) => [
        ...current,
        {
          id: card.id,
          uid: cardData.uid,
          configurations: cardConfigurationDocs.docs.length,
          balance: cardData.balance,
          paymentURL: cardData.paymentURL,
          subscription: cardData.subscription,
        },
      ]);
      // Hide loading
      setLoading(false);
    });
    // Hide loading
    setLoading(false);
  };

  const handleCardConfigurationsSelect = (card) => {
    global.selectedCard = card;
    navigation.navigate("Configurations");
  };

  const handleCardHistoriesSelect = (card) => {
    global.selectedCard = card;
    navigation.navigate("CardHistories");
  };

  const handleCardTransactionsSelect = (card) => {
    global.selectedCard = card;
    navigation.navigate("Transactions");
  };

  const numberFormat = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const handleAccountSelect = () => {
    navigation.navigate("Account");
  };

  const handleMonthlyUpgradeConfiguration = () => {
    openDocumentURL("https://buy.stripe.com/5kA7vJ76K4TTdZmbIJ");
  };

  const handleYearlyUpgradeConfiguration = () => {
    openDocumentURL("https://buy.stripe.com/5kA7vJ76K4TTdZmbIJ");
  };

  const openDocumentURL = (url) => {
    if (url.substring(0, 7) != "http://" && url.substring(0, 8) != "https://") {
      url = "https://" + url;
    }

    const element = document.createElement("a");
    element.href = url;

    // Safari doesn't support opening new windows
    if (!isSafari) {
      element.target = "_blank";
    }
    document.body.appendChild(element);
    element.click();
  };

  const handleResendEmailVerification = () => {
    // Send the user an email verification email
    sendEmailVerification(auth.currentUser).then(() => {});
    setEmailVerificationMessage("Email sent. Please check your email.");
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      {global.userDaysSinceRegistration != 0 ? (
        <View>
          <Text style={{ margin: 10, color: "red", textAlign: "center" }}>
            {emailVerificationMessage}
          </Text>
          <TouchableOpacity
            style={styles.buttonSecondary}
            onPress={() => handleResendEmailVerification()}
          >
            <Text style={styles.buttonTitle}>Resend Email Verification</Text>
          </TouchableOpacity>
        </View>
      ) : null}
      <FlatList
        style={{ width: "100%" }}
        data={userRegisteredCards}
        keyExtractor={(userRegisteredCards) =>
          userRegisteredCards.uid.toString()
        }
        onRefresh={() => onRefresh()}
        refreshing={loading}
        renderItem={({ item }) => (
          <Card style={{ width: "90%" }}>
            <Card.Title style={{ color: "#C9060C" }}>
              ToSH: {item.uid} - {item.subscription}
            </Card.Title>
            <Card.Divider />
            <View style={{ flex: 1, flexDirection: "column" }}>
              <TouchableOpacity
                onPress={() => handleCardConfigurationsSelect(item)}
              >
                <View style={{ flex: 1, flexDirection: "row", margin: 5 }}>
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <Image
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 10,
                        tintColor: "#69AD4C",
                      }}
                      source={require("../../assets/material-icons/settings.png")}
                    />
                    <Text style={{ fontSize: 16, color: "#69AD4C" }}>
                      Configurations: {item.configurations}
                    </Text>
                  </View>
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                      alignSelf: "flex-end",
                      tintColor: "#69AD4C",
                    }}
                    source={require("../../assets/material-icons/edit.png")}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => handleCardTransactionsSelect(item)}
              >
                <View style={{ flex: 1, flexDirection: "row", margin: 5 }}>
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <Image
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 10,
                        tintColor: "#3671C2",
                      }}
                      source={require("../../assets/material-icons/account_balance.png")}
                    />
                    <Text style={{ fontSize: 16, color: "#3671C2" }}>
                      Balance:{" "}
                      {item.balance
                        ? numberFormat(item.balance)
                        : numberFormat(0)}
                    </Text>
                  </View>
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                      alignSelf: "flex-end",
                      tintColor: "#3671C2",
                    }}
                    source={require("../../assets/material-icons/visibility.png")}
                  />
                </View>
              </TouchableOpacity>
              <Card.Divider />
              <TouchableOpacity onPress={() => handleCardHistoriesSelect(item)}>
                <View style={{ flex: 1, flexDirection: "row", margin: 5 }}>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                    }}
                  >
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Image
                        style={{
                          width: 24,
                          height: 24,
                          marginRight: 10,
                          tintColor: "#3671C2",
                        }}
                        source={require("../../assets/material-icons/browse_activity.png")}
                      />
                      <Text style={{ fontSize: 16, color: "#3671C2" }}>
                        Histories{" "}
                      </Text>
                    </View>
                    <Image
                      style={{
                        width: 24,
                        height: 24,
                        alignSelf: "flex-end",
                        tintColor: "#3671C2",
                      }}
                      source={require("../../assets/material-icons/visibility.png")}
                    />
                  </View>
                </View>
              </TouchableOpacity>
              {/* {item.subscription == "Free Trial" ||
              item.subscription == "Expired" ? (
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => handleMonthlyUpgradeConfiguration()}
                >
                  <Text style={styles.buttonTitle}>Upgrade - $4.99/Month</Text>
                </TouchableOpacity>
              ) : null}
              {item.subscription == "Free Trial" ||
              item.subscription == "Expired" ? (
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => handleYearlyUpgradeConfiguration()}
                >
                  <Text style={styles.buttonTitle}>Upgrade - $49.99/Year</Text>
                </TouchableOpacity>
              ) : null} */}
            </View>
          </Card>
        )}
      />
    </View>
  );
}
