import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  Switch,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Card } from "@rneui/themed";
import Spinner from "react-native-loading-spinner-overlay";

import styles from "../common/styles";

import YesNoModal from "../common/components/YesNoModal";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const fs = getFirestore(firebaseApp);

export default function CardScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const initialState = [];
  const [cardConfigurations, setCardConfigurations] = useState(initialState);

  const [showYesNoModal, setShowYesNoModal] = useState(false);
  const [yesNoModalTitle, setYesNoModalTitle] = useState("");
  const [yesNoModalMessage, setYesNoModalMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState(initialState);

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: () => (
        <TouchableOpacity onPress={() => handlePreviewSelected()}>
          <Image
            style={{
              width: 36,
              height: 36,
              tintColor: "#3671C2",
              marginRight: 10,
            }}
            source={require("../../assets/material-icons/gallery_thumbnail.png")}
          />
        </TouchableOpacity>
      ),
    });

    navigation.addListener("focus", () => {
      getCardConfigurations();
    });

    setLoading(false);
  }, []);

  const getCardConfigurations = async () => {
    // Show loading
    setLoading(true);

    // Reset
    setCardConfigurations(initialState);

    // Get all the configurations
    const cardConfigurationDocs = await getDocs(
      query(
        collection(fs, "cards/" + global.selectedCard.id + "/configurations"),
        orderBy("visualOrder", "asc")
      )
    );

    // Loop and load data
    cardConfigurationDocs.forEach((cardConfiguration) => {
      const configurationData = cardConfiguration.data();
      setCardConfigurations((current) => [
        ...current,
        {
          id: cardConfiguration.id,
          message: configurationData.message,
          metadata: configurationData.metadata,
          isVisible: configurationData.isVisible
            ? configurationData.isVisible
            : false,
          visualOrder: configurationData.visualOrder
            ? configurationData.visualOrder
            : 1,

          isForAnonymousPayments: configurationData.isForAnonymousPayments
            ? configurationData.isForAnonymousPayments
            : false,
          isWithdrawMethod: configurationData.isWithdrawMethod
            ? configurationData.isWithdrawMethod
            : false,

          isVCard: configurationData.isVCard
            ? configurationData.isVCard
            : false,
        },
      ]);

      // Hide loading
      setLoading(false);
    });
    // Hide loading
    setLoading(false);
  };

  const handleAddConfigurationSelected = () => {
    global.selectedCardConfiguration = undefined;
    navigation.navigate("Configuration Details");
  };

  const handleEditConfigurationSelected = (item) => {
    global.selectedCardConfiguration = item;
    navigation.navigate("Configuration Details");
  };

  const handleDeleteConfigurationSelected = async (item) => {
    setDeleteItem(item);
    setYesNoModalTitle("Confirm Delete?");
    setYesNoModalMessage(
      "Are you sure you want to delete this card configuration?"
    );
    setShowYesNoModal(true);
  };

  const handlePreviewSelected = async () => {
    setLoading(true);

    global.isPreview = true;
    global.cardId = global.selectedCard.id;

    // Add history to card
    const historyRef = await addDoc(
      collection(fs, "cards/" + global.selectedCard.id + "/histories"),
      {
        type: "Configurations Previewed",
        metadata: "Configurations Previewed.",
        timestamp: new Date(),
      }
    );

    navigation.navigate("Launch");

    setLoading(false);
  };

  const handleToggleIsVisibleSwitchSelected = async (value, item) => {
    setLoading(true);

    item.isVisible = value;

    const cardConfigurationDoc = doc(
      fs,
      "/cards/" + global.selectedCard.id + "/configurations/",
      item.id
    );

    // Update configuration
    await updateDoc(cardConfigurationDoc, {
      isVisible: item.isVisible,
    });

    getCardConfigurations();

    setLoading(false);
  };

  const handleIncrementVisualOrderSelected = async (item) => {
    setLoading(true);

    const cardConfigurationDoc = doc(
      fs,
      "/cards/" + global.selectedCard.id + "/configurations/",
      item.id
    );

    // Update configuration
    await updateDoc(cardConfigurationDoc, {
      visualOrder: item.visualOrder + 1,
    });

    getCardConfigurations();

    setLoading(false);
  };

  const handleDecrementVisualOrderSelected = async (item) => {
    setLoading(true);

    if (item.visualOrder > 1) {
      const cardConfigurationDoc = doc(
        fs,
        "/cards/" + global.selectedCard.id + "/configurations/",
        item.id
      );

      // Update configuration
      await updateDoc(cardConfigurationDoc, {
        visualOrder: item.visualOrder - 1,
      });

      getCardConfigurations();
    }

    setLoading(false);
  };

  const handleModalResponseCallback = async (response) => {
    if (response == 1) {
      await deleteDoc(
        doc(
          fs,
          "cards/" + global.selectedCard.id + "/configurations",
          deleteItem.id
        )
      );
      getCardConfigurations();
      // Add history to card
      const historyRef = await addDoc(
        collection(fs, "cards/" + global.selectedCard.id + "/histories"),
        {
          type: "Configuration Deleted",
          metadata: "Configuration Deleted: " + deleteItem.type,
          timestamp: new Date(),
        }
      );
    }

    setShowYesNoModal(false);
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          marginTop: 10,
          paddingLeft: 20,
          paddingRight: 15,
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            color: "#C9060C",
            fontSize: 16,
            fontWeight: "bold",
            marginTop: 10,
          }}
        >
          ToSH: {global.selectedCard.uid}{" "}
        </Text>
        <TouchableOpacity onPress={() => handleAddConfigurationSelected()}>
          <Image
            style={{
              width: 36,
              height: 36,
              tintColor: "#69AD4C",
            }}
            source={require("../../assets/material-icons/add_circle.png")}
          />
        </TouchableOpacity>
      </View>
      <FlatList
        style={{ width: "100%" }}
        data={cardConfigurations}
        keyExtractor={(cardConfigurations) => cardConfigurations.id}
        onRefresh={() => onRefresh()}
        refreshing={loading}
        renderItem={({ item }) => (
          <Card style={{ width: "90%" }}>
            <Card.Title style={{ color: "#C9060C" }}>
              {item.visualOrder}: {item.message}
            </Card.Title>
            <Card.Divider />
            <View style={{ flex: 1, flexDirection: "column" }}>
              <View style={{ flex: 1, flexDirection: "row", margin: 5 }}>
                <Switch
                  onValueChange={(value) =>
                    handleToggleIsVisibleSwitchSelected(value, item)
                  }
                  value={item.isVisible}
                  style={{ marginRight: 5 }}
                  trackColor={{ true: "#69AD4C", false: "grey" }}
                  activeThumbColor={"#ffffff"}
                />

                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() => handleEditConfigurationSelected(item)}
                >
                  <View style={{ flexDirection: "row", flex: 1 }}>
                    <Text
                      numberOfLines={1}
                      style={{ flex: 1, fontSize: 16, color: "#69AD4C" }}
                    >
                      {item.isVCard
                        ? "Virtual Contact ..."
                        : item.isForAnonymousPayments
                        ? "Anonymous Payments ... "
                        : item.metadata}
                    </Text>

                    <Image
                      style={{
                        width: 24,
                        height: 24,
                        alignSelf: "flex-end",
                        tintColor: "#69AD4C",
                      }}
                      source={require("../../assets/material-icons/edit.png")}
                    />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => handleDeleteConfigurationSelected(item)}
                >
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                      alignSelf: "flex-end",
                      tintColor: "#C9060C",
                    }}
                    source={require("../../assets/material-icons/delete.png")}
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => handleIncrementVisualOrderSelected(item)}
                >
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                      tintColor: "#3671C2",
                    }}
                    source={require("../../assets/material-icons/keyboard_double_arrow_down.png")}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => handleDecrementVisualOrderSelected(item)}
                >
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                      tintColor: "#3671C2",
                    }}
                    source={require("../../assets/material-icons/keyboard_double_arrow_up.png")}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </Card>
        )}
      />
      {showYesNoModal ? (
        <YesNoModal
          title={yesNoModalTitle}
          message={yesNoModalMessage}
          responseCallback={handleModalResponseCallback}
        ></YesNoModal>
      ) : null}
    </View>
  );
}
