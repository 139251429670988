import React, { useEffect, useState } from "react";
import { Text, View, FlatList, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { HeaderBackButton } from "@react-navigation/elements";
import Spinner from "react-native-loading-spinner-overlay";
import { isSafari } from "react-device-detect";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const fs = getFirestore(firebaseApp);

export default function LaunchScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const initialState = [];
  const [cardConfigurations, setCardConfigurations] =
    React.useState(initialState);
  const [isFetching, setIsFetching] = React.useState(false);

  const [showNoConfigurations, setShowNoConfigurations] = useState(false);

  useEffect(() => {
    navigation.addListener("focus", () => {
      setLoading(true);
      if (global.isPreview) {
        navigation.setOptions({
          headerTitle: () => (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                style={{ height: 48, width: 250 }}
                resizeMode="contain"
                source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
              />
            </View>
          ),
          headerTitleAlign: "center",
          headerLeft: () => (
            <HeaderBackButton onPress={() => navigation.goBack()} />
          ),
          headerRight: null,
        });
      } else {
        navigation.setOptions({
          headerTitle: () => (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                style={{ height: 48, width: 250 }}
                resizeMode="contain"
                source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
              />
            </View>
          ),
          headerTitleAlign: "center",
          headerLeft: null,
          headerRight: () => (
            <TouchableOpacity onPress={() => handleLoginSelected()}>
              <Image
                style={{
                  width: 36,
                  height: 36,
                  marginRight: 10,
                  tintColor: "#3671C2",
                }}
                source={require("../../assets/material-icons/login.png")}
              />
            </TouchableOpacity>
          ),
        });
      }

      initializeScreen();
      getCardConfigurations();
      setLoading(false);
    });
  }, []);

  const initializeScreen = async () => {
    // Login as Admin to save the history
    await signInWithEmailAndPassword(
      auth,
      "admin@taporscanhere.com",
      "T0Myj35Rk#t7"
    ).then(async (userCredential) => {
      // Add history to card
      const historyRef = await addDoc(
        collection(fs, "cards/" + global.cardId + "/histories"),
        {
          type: "Launch Accessed",
          metadata: "Launch Accessed",
          timestamp: new Date(),
        }
      );
      await auth.signOut();
    });
  };

  const getCardConfigurations = async () => {
    // Reset
    setCardConfigurations(initialState);
    const isCardFreeOrPaidValue = await isCardFreeOrPaid();

    if (isCardFreeOrPaidValue) {
      // Get all the configurations
      const cardConfigurationDocs = await getDocs(
        query(
          collection(fs, "cards/" + global.cardId + "/configurations"),
          where("isVisible", "==", true),
          orderBy("visualOrder", "asc")
        )
      );

      // Loop on all configurations
      cardConfigurationDocs.forEach(async (cardConfiguration) => {
        const configurationData = cardConfiguration.data();

        // Check for only one configuration and a vCard
        if (cardConfigurationDocs.docs.length == 1) {
          handleConfigurationSelected(configurationData);
        }

        await setCardConfigurations((current) => [
          ...current,
          {
            id: cardConfiguration.id,
            message: configurationData.message,
            metadata: configurationData.metadata,
            isVisible: configurationData.isVisible
              ? configurationData.isVisible
              : false,
            visualOrder: configurationData.visualOrder
              ? configurationData.visualOrder
              : false,
            isWithdrawMethod: configurationData.isWithdrawMethod
              ? configurationData.isWithdrawMethod
              : false,
            isForAnonymousPayments: configurationData.isForAnonymousPayments
              ? configurationData.isForAnonymousPayments
              : false,
            isVCard: configurationData.isVCard
              ? configurationData.isVCard
              : false,
          },
        ]);

        if (configurationData.length == 0) {
          setShowNoConfigurations(true);
        } else {
          setShowNoConfigurations(false);
        }
      });
    }
  };

  const isCardFreeOrPaid = async () => {
    let result = true;

    // Get a reference to the cards collection
    const cardsRefs = collection(fs, "cards");

    // Query for the card uid
    const cardQuery = query(cardsRefs, where("uid", "==", global.uid));
    const cardDocs = await getDocs(cardQuery);

    cardDocs.forEach(async (card) => {
      const cardData = card.data();

      if (cardData.subscription == "Expired") {
        result = false;
      }
    });

    return result;
  };

  const handleConfigurationSelected = async (item) => {
    // Login as Admin to save the history
    await signInWithEmailAndPassword(
      auth,
      "admin@taporscanhere.com",
      "T0Myj35Rk#t7"
    ).then(async (userCredential) => {
      // Add history to card
      const historyRef = await addDoc(
        collection(fs, "cards/" + global.cardId + "/histories"),
        {
          type: "Launch Selected",
          metadata: "Launch Selected: " + item.message,
          timestamp: new Date(),
        }
      );
      await auth.signOut();
    });

    if (item.isVCard) {
      downloadTxtFile(item.metadata);
    } else if (item.isForAnonymousPayments) {
      // Login as Admin to save the transactions
      await signInWithEmailAndPassword(
        auth,
        "admin@taporscanhere.com",
        "T0Myj35Rk#t7"
      ).then(async (userCredential) => {
        // Add transaction to card
        const transactionRef = await addDoc(
          collection(fs, "cards/" + global.cardId + "/transactions"),
          {
            action: "Payment Request",
            timestamp: new Date(),
          }
        );

        // Add history to card
        const historyRef = await addDoc(
          collection(fs, "cards/" + global.cardId + "/histories"),
          {
            type: "Payment Request",
            metadata: "Payment Request: " + item.message,
            timestamp: new Date(),
          }
        );

        await auth.signOut();
      });

      openDocumentURL(item.metadata);
    } else {
      openDocumentURL(item.metadata);
    }
  };

  const handleLoginSelected = async () => {
    // Froce application to reload to remove navigation stack issues.
    openDocumentURL("https://app.taporscanhere.com");
  };

  const downloadTxtFile = (vcfText) => {
    const element = document.createElement("a");
    const file = new Blob([vcfText], { type: "text/x-vcard;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = "contact.vcf";
    document.body.appendChild(element);
    element.click();
  };

  const openDocumentURL = (url) => {
    if (url.substring(0, 7) != "http://" && url.substring(0, 8) != "https://") {
      url = "https://" + url;
    }

    const element = document.createElement("a");
    element.href = url;

    // Safari doesn't support opening new windows
    if (!isSafari) {
      element.target = "_blank";
    }
    document.body.appendChild(element);
    element.click();
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      {cardConfigurations.length > 0 ? (
        <View style={{ flex: 1, width: "100%" }}>
          <FlatList
            style={{ width: "100%" }}
            data={cardConfigurations}
            keyExtractor={(cardConfigurations) => cardConfigurations.id}
            onRefresh={() => onRefresh()}
            refreshing={isFetching}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.buttonSecondaryLaunch}
                onPress={() => handleConfigurationSelected(item)}
              >
                <Image
                  style={styles.launchImage}
                  source={require("../../assets/material-icons/ads_click.png")}
                />

                <Text style={styles.buttonTitleLaunch}>{item.message}</Text>
              </TouchableOpacity>
            )}
          />
        </View>
      ) : null}
      {showNoConfigurations ? (
        <View style={{ margin: 10 }}>
          <Text style={{ margin: 5 }}>
            We're sorry but this card doesn't appear to be configured or doesn't
            have a subscription plan.
          </Text>
          <Text style={{ margin: 5 }}>
            Please contact the owner of this account.
          </Text>
          <Text style={{ margin: 5 }}>
            If you are the owner of this card, please login and either configure
            your card, or upgrade. Thank you.
          </Text>
        </View>
      ) : null}
      <Text style={{ marginBottom: 10 }}>version: {global.versionNumber}</Text>
    </View>
  );
}
