import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Image,
  Switch,
  useWindowDimensions,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";
import StringBuilder from "yassb";
import * as ImagePicker from "expo-image-picker";
import moment from "moment/moment";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { isSafari } from "react-device-detect";

import styles from "../common/styles";

import YesNoModal from "../common/components/YesNoModal";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDoc,
  addDoc,
  updateDoc,
  collection,
  doc,
  deleteDoc,
} from "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const fs = getFirestore(firebaseApp);

export default function ConfigurationDetailsScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const { height, width } = useWindowDimensions();

  const [message, setMessage] = useState("");
  const [configurationMetadata, setConfigurationMetadata] = useState("");

  const [isVisible, setIsVisible] = useState(true);
  const toggleIsVisibleSwitch = () =>
    setIsVisible((previousState) => !previousState);

  const [visualOrder, setVisualOrder] = useState(1);

  const [isForAnonymousPayments, setIsForAnonymousPayments] = useState(false);
  const toggleIsForAnanymousePaymentsSwitch = () => {
    setIsForAnonymousPayments((previousState) => !previousState);
    setIsVCard(false);
    setIsWithdrawMethod(false);
  };

  const [isWithdrawMethod, setIsWithdrawMethod] = useState(false);
  const toggleIsWithdrawSwitch = () => {
    setIsWithdrawMethod((previousState) => !previousState);
    setIsVCard(false);
  };

  const [isVCard, setIsVCard] = useState(false);
  const toggleIsVCardSwitch = () => {
    setIsVCard((previousState) => !previousState);
    setIsWithdrawMethod(false);
    setIsForAnonymousPayments(false);
    setConfigurationMetadata("");
  };

  const [photo, setPhoto] = useState(null);
  const [logo, setLogo] = useState(null);

  const [prefix, setPrefix] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [nickName, setNickName] = useState("");
  const [homeAddressStreet, setHomeAddressStreet] = useState("");
  const [homeAddressCity, setHomeAddressCity] = useState("");
  const [homeAddressState, setHomeAddressState] = useState("");
  const [homeAddressZip, setHomeAddressZip] = useState("");
  const [homeAddressCountry, setHomeAddressCountry] = useState("");
  const [homePhoneNumber, setHomePhoneNumber] = useState("");
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [homeEmailAddress, setHomeEmailAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyOrganization, setCompanyOrganization] = useState("");
  const [companyOrganizationalUnit, setCompanyOrganizationalUnit] =
    useState("");
  const [companyRole, setCompanyRole] = useState("");
  const [companyTitle, setCompanyTitle] = useState("");
  const [workAddressStreet, setWorkAddressStreet] = useState("");
  const [workAddressCity, setWorkAddressCity] = useState("");
  const [workAddressState, setWorkAddressState] = useState("");
  const [workAddressZip, setWorkAddressZip] = useState("");
  const [workAddressCountry, setWorkAddressCountry] = useState("");
  const [workPhoneNumber, setWorkPhoneNumber] = useState("");
  const [workEmailAddress, setWorkEmailAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [tags, setTags] = useState("");
  const [note, setNote] = useState("");

  const [photoValidationErrors, setPhotoValidationErrors] = useState("");
  const [logoValidationErrors, setLogoValidationErrors] = useState("");
  const [validationErrors, setValidationErrors] = useState("");

  const [showYesNoModal, setShowYesNoModal] = useState(false);
  const [yesNoModalTitle, setYesNoModalTitle] = useState("");
  const [yesNoModalMessage, setYesNoModalMessage] = useState("");

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: () => (
        <TouchableOpacity onPress={() => handleDeleteSelected()}>
          <Image
            style={{
              width: 36,
              height: 36,
              tintColor: "#C9060C",
              marginRight: 10,
            }}
            source={require("../../assets/material-icons/delete.png")}
          />
        </TouchableOpacity>
      ),
    });

    if (global.selectedCardConfiguration) {
      getCardConfiguration();
    } else {
      setMessage("");
      setConfigurationMetadata("");

      setIsVisible(true);
      setVisualOrder(1);

      setIsForAnonymousPayments(false);
      setIsWithdrawMethod(false);
    }

    setLoading(false);
  }, []);

  const getCardConfiguration = async () => {
    // Show loading
    setLoading(true);

    // Get the selected card configuration doc
    const cardConfigurationDocReference = doc(
      fs,
      "/cards/" + global.selectedCard.id + "/configurations/",
      global.selectedCardConfiguration.id
    );
    const cardConfigurationDoc = await getDoc(cardConfigurationDocReference);

    await setMessage(cardConfigurationDoc.data().message);
    await setConfigurationMetadata(cardConfigurationDoc.data().metadata);

    await setIsVisible(
      cardConfigurationDoc.data().isVisible
        ? cardConfigurationDoc.data().isVisible
        : false
    );
    await setVisualOrder(
      cardConfigurationDoc.data().visualOrder
        ? cardConfigurationDoc.data().visualOrder
        : 1
    );

    await setIsForAnonymousPayments(
      cardConfigurationDoc.data().isForAnonymousPayments
        ? cardConfigurationDoc.data().isForAnonymousPayments
        : false
    );

    await setIsWithdrawMethod(
      cardConfigurationDoc.data().isWithdrawMethod
        ? cardConfigurationDoc.data().isWithdrawMethod
        : false
    );

    await setIsVCard(
      cardConfigurationDoc.data().isVCard
        ? cardConfigurationDoc.data().isVCard
        : false
    );

    if (cardConfigurationDoc.data().isVCard) {
      await unPackVCardData(cardConfigurationDoc.data().metadata);
    }

    // Hide loading
    setLoading(false);
  };

  const handleSaveConfigurationSelected = async () => {
    setLoading(true);

    // Reset
    setValidationErrors("");
    const validationErrorsData = new StringBuilder();

    // Reset all data fields.
    if (!isVCard) {
      await setPhoto(null);
      await setLogo(null);

      await setPrefix("");
      await setFirstName("");
      await setMiddleName("");
      await setLastName("");
      await setSuffix("");
      await setNickName("");
      await setHomeAddressStreet("");
      await setHomeAddressCity("");
      await setHomeAddressState("");
      await setHomeAddressZip("");
      await setHomeAddressCountry("");
      await setHomePhoneNumber("");
      await setMobilePhoneNumber("");
      await setFaxNumber("");
      await setHomeEmailAddress("");
      await setCompanyName("");
      await setCompanyOrganization("");
      await setCompanyOrganizationalUnit("");
      await setCompanyRole("");
      await setCompanyTitle("");
      await setWorkAddressStreet("");
      await setWorkAddressCity("");
      await setWorkAddressState("");
      await setWorkAddressZip("");
      await setWorkAddressCountry("");
      await setWorkPhoneNumber("");
      await setWorkEmailAddress("");
      await setWebsite("");
      await setTags("");
      await setNote("");
    }

    if (message == "") {
      validationErrorsData.addLine("Message is Required.");
    }

    if (!isVCard && !isForAnonymousPayments && configurationMetadata == "") {
      validationErrorsData.addLine("A Link is Required.");
    }

    if (isVCard && firstName == "" && lastName == "") {
      validationErrorsData.addLine("Your first or last name is requred.");
    }

    if (validationErrorsData.toString() != "") {
      setLoading(false);
      setValidationErrors(validationErrorsData.toString());
    } else {
      // Show loading
      setLoading(true);

      // Check for add/edit
      if (global.selectedCardConfiguration) {
        const cardConfigurationDoc = doc(
          fs,
          "/cards/" + global.selectedCard.id + "/configurations/",
          global.selectedCardConfiguration.id
        );

        if (isVCard) {
          const updatedVCardData = await packVCardData();
          await setConfigurationMetadata(updatedVCardData);
          // Update configuration
          await updateDoc(cardConfigurationDoc, {
            message: message,
            metadata: updatedVCardData,
            isVisible: isVisible,
            visualOrder: visualOrder,
            isWithdrawMethod: isWithdrawMethod,
            isForAnonymousPayments: isForAnonymousPayments,
            isVCard: isVCard,
          });
        } else if (isForAnonymousPayments) {
          // Update configuration
          await updateDoc(cardConfigurationDoc, {
            message: message,
            metadata: global.selectedCard.paymentURL,
            isVisible: isVisible,
            visualOrder: visualOrder,
            isWithdrawMethod: isWithdrawMethod,
            isForAnonymousPayments: isForAnonymousPayments,
            isVCard: isVCard,
          });
        } else {
          // Update configuration
          await updateDoc(cardConfigurationDoc, {
            message: message,
            metadata: configurationMetadata,
            isVisible: isVisible,
            visualOrder: visualOrder,
            isWithdrawMethod: isWithdrawMethod,
            isForAnonymousPayments: isForAnonymousPayments,
            isVCard: isVCard,
          });
        }

        // Add history to card
        const historyRef = await addDoc(
          collection(fs, "cards/" + global.selectedCard.id + "/histories"),
          {
            type: "Configuration Updated",
            metadata: "Configuration Updated: " + message,
            timestamp: new Date(),
          }
        );
      } else {
        if (isVCard) {
          const updatedVCardData = await packVCardData();
          await setConfigurationMetadata(updatedVCardData);
          // Add new configuration
          const cardCongfigurationDoc = await addDoc(
            collection(
              fs,
              "cards/" + global.selectedCard.id + "/configurations"
            ),
            {
              message: message,
              metadata: updatedVCardData,
              isVisible: isVisible,
              visualOrder: visualOrder,
              isWithdrawMethod: isWithdrawMethod,
              isForAnonymousPayments: isForAnonymousPayments,
              isVCard: isVCard,
            }
          );
        } else if (isForAnonymousPayments) {
          // Add new configuration
          const cardCongfigurationDoc = await addDoc(
            collection(
              fs,
              "cards/" + global.selectedCard.id + "/configurations"
            ),
            {
              message: message,
              metadata: global.selectedCard.paymentURL,
              isVisible: isVisible,
              visualOrder: visualOrder,
              isWithdrawMethod: isWithdrawMethod,
              isForAnonymousPayments: isForAnonymousPayments,
              isVCard: isVCard,
            }
          );
        } else {
          // Add new configuration
          const cardCongfigurationDoc = await addDoc(
            collection(
              fs,
              "cards/" + global.selectedCard.id + "/configurations"
            ),
            {
              message: message,
              metadata: configurationMetadata,
              isVisible: isVisible,
              visualOrder: visualOrder,
              isWithdrawMethod: isWithdrawMethod,
              isForAnonymousPayments: isForAnonymousPayments,
              isVCard: isVCard,
            }
          );
        }

        // Add history to card
        const historyRef = await addDoc(
          collection(fs, "cards/" + global.selectedCard.id + "/histories"),
          {
            type: "Configuration Added",
            metadata: "Configuration Added: " + message,
            timestamp: new Date(),
          }
        );
      }

      // Hide loading
      setLoading(false);

      navigation.goBack();
    }
  };

  const handleTestConfigurationSelected = () => {
    setLoading(true);

    if (isVCard) {
      packVCardData();
      downloadTxtFile(configurationMetadata);
    } else {
      openDocumentURL(configurationMetadata);
    }
    setLoading(false);
  };

  const downloadTxtFile = (vcfText) => {
    setLoading(true);

    const element = document.createElement("a");
    const file = new Blob([vcfText], { type: "text/x-vcard;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = "contact.vcf";
    document.body.appendChild(element);
    element.click();

    setLoading(false);
  };

  const openDocumentURL = (url) => {
    if (url.substring(0, 7) != "http://" && url.substring(0, 8) != "https://") {
      url = "https://" + url;
    }

    const element = document.createElement("a");
    element.href = url;

    // Safari doesn't support opening new windows
    if (!isSafari) {
      element.target = "_blank";
    }
    document.body.appendChild(element);
    element.click();
  };

  const packVCardData = () => {
    const vCardData = new StringBuilder();

    vCardData.addLine("BEGIN:VCARD");
    vCardData.addLine("VERSION:2.1");

    if (photo) {
      vCardData.addLine("PHOTO;PNG;ENCODING=BASE64:" + photo);
    }

    if (logo) {
      vCardData.addLine("LOGO;PNG;ENCODING=BASE64:" + logo);
    }

    if (
      (firstName && firstName.length) > 0 ||
      (lastName && lastName.length > 0) ||
      (middleName && middleName.length > 0) ||
      (prefix && prefix.length > 0) ||
      (suffix && suffix.length > 0)
    ) {
      vCardData.addLine();
      vCardData.add("FN:");
      if (prefix && prefix.length > 0) {
        vCardData.add(prefix.trim()).add(" ");
      }
      if (firstName && firstName.length > 0) {
        vCardData.add(firstName.trim()).add(" ");
      }
      if (middleName && middleName.length > 0) {
        vCardData.add(middleName.trim()).add(" ");
      }
      if (lastName && lastName.length > 0) {
        vCardData.add(lastName.trim()).add(" ");
      }
      if (suffix && suffix.length > 0) {
        vCardData.add(suffix.trim()).add(" ");
      }

      vCardData.addLine();
      vCardData.add("N:");
      if (lastName && lastName.length > 0) {
        vCardData.add(lastName.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (middleName && middleName.length > 0) {
        vCardData.add(middleName.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (firstName && firstName.length > 0) {
        vCardData.add(firstName.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (prefix && prefix.length > 0) {
        vCardData.add(prefix.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (suffix && suffix.length > 0) {
        vCardData.add(suffix.trim()).add(";");
      } else {
        vCardData.add(";");
      }
    }

    if (nickName && nickName.length > 0) {
      vCardData.addLine("NICKNAME:" + nickName);
    }

    if (
      (homeAddressStreet && homeAddressStreet.length) > 0 ||
      (homeAddressCity && homeAddressCity.length > 0) ||
      (homeAddressState && homeAddressState.length > 0) ||
      (homeAddressZip && homeAddressZip.length > 0) ||
      (homeAddressCountry && homeAddressCountry.length > 0)
    ) {
      vCardData.addLine();
      vCardData.add("ADR;TYPE=home:;;");
      if (homeAddressStreet && homeAddressStreet.length > 0) {
        vCardData.add(homeAddressStreet.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (homeAddressCity && homeAddressCity.length > 0) {
        vCardData.add(homeAddressCity.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (homeAddressState && homeAddressState.length > 0) {
        vCardData.add(homeAddressState.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (homeAddressZip && homeAddressZip.length > 0) {
        vCardData.add(homeAddressZip.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (homeAddressCountry && homeAddressCountry.length > 0) {
        vCardData.add(homeAddressCountry.trim()).add(";");
      } else {
        vCardData.add(";");
      }
    }

    if (homePhoneNumber && homePhoneNumber.length > 0) {
      vCardData.addLine("TEL;TYPE=home:" + homePhoneNumber.trim());
    }

    if (mobilePhoneNumber && mobilePhoneNumber.length > 0) {
      vCardData.addLine("TEL;TYPE=cell:" + mobilePhoneNumber.trim());
    }

    if (faxNumber && faxNumber.length > 0) {
      vCardData.addLine("TEL;TYPE=fax:" + faxNumber.trim());
    }

    if (homeEmailAddress && homeEmailAddress.length > 0) {
      vCardData.addLine("EMAIL;TYPE=home:" + homeEmailAddress.trim());
    }

    if (
      (companyName && companyName.length) > 0 ||
      (companyOrganization && companyOrganization.length > 0) ||
      (companyOrganizationalUnit && companyOrganizationalUnit.length > 0)
    ) {
      vCardData.addLine();
      vCardData.add("ORG:");
      if (companyName && companyName.length > 0) {
        vCardData.add(companyName.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (companyOrganization && companyOrganization.length > 0) {
        vCardData.add(companyOrganization.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (companyOrganizationalUnit && companyOrganizationalUnit.length > 0) {
        vCardData.add(companyOrganizationalUnit.trim()).add(";");
      } else {
        vCardData.add(";");
      }
    }

    if (companyRole && companyRole.length > 0) {
      vCardData.addLine("ROLE:" + companyRole.trim());
    }
    if (companyTitle && companyTitle.length > 0) {
      vCardData.addLine("TITLE:" + companyTitle.trim());
    }

    if (
      (workAddressStreet && workAddressStreet.length) > 0 ||
      (workAddressCity && workAddressCity.length > 0) ||
      (workAddressState && workAddressState.length > 0) ||
      (workAddressZip && workAddressZip.length > 0) ||
      (workAddressCountry && workAddressCountry.length > 0)
    ) {
      vCardData.addLine();
      vCardData.add("ADR;TYPE=work:;;");
      if (workAddressStreet && workAddressStreet.length > 0) {
        vCardData.add(workAddressStreet.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (workAddressCity && workAddressCity.length > 0) {
        vCardData.add(workAddressCity.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (workAddressState && workAddressState.length > 0) {
        vCardData.add(workAddressState.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (workAddressZip && workAddressZip.length > 0) {
        vCardData.add(workAddressZip.trim()).add(";");
      } else {
        vCardData.add(";");
      }
      if (workAddressCountry && workAddressCountry.length > 0) {
        vCardData.add(workAddressCountry.trim()).add(";");
      } else {
        vCardData.add(";");
      }
    }

    if (workPhoneNumber && workPhoneNumber.length > 0) {
      vCardData.addLine("TEL;TYPE=work:" + workPhoneNumber.trim());
    }

    if (workEmailAddress && workEmailAddress.length > 0) {
      vCardData.addLine("EMAIL;TYPE=work:" + workEmailAddress.trim());
    }

    if (website && website.length > 0) {
      vCardData.addLine("URL:" + website.trim());
    }

    if (tags && tags.length > 0) {
      vCardData.addLine("CATEGORIES:" + tags.trim());
    }

    if (note && note.length > 0) {
      vCardData.addLine("NOTE:" + note.trim());
    }

    vCardData.addLine("PROFILE:VCARD");
    vCardData.addLine(
      "REV:" +
        moment()
          .toISOString()
          .replace("-", "")
          .replace(":", "")
          .replace(".", "")
          .replace(":", "")
          .replace("-", "")
    );

    vCardData.addLine("END:VCARD");

    return vCardData.toString();
  };

  const unPackVCardData = (vCardMetadata) => {
    const vCardDataSplit = vCardMetadata.split("\n");

    vCardDataSplit.forEach(async (element) => {
      if (element.substring(0, 6) == "PHOTO;") {
        await setPhoto(element.replace("PHOTO;PNG;ENCODING=BASE64:", ""));
      }

      if (element.substring(0, 5) == "LOGO;") {
        await setLogo(element.replace("LOGO;PNG;ENCODING=BASE64:", ""));
      }

      if (element.substring(0, 2) == "N:") {
        const nameSplit = element.replace("N:", "").split(";");

        if (nameSplit[0] && nameSplit[0].length > 0) {
          await setLastName(nameSplit[0]);
        }
        if (nameSplit[1] && nameSplit[1].length > 0) {
          await setMiddleName(nameSplit[1]);
        }
        if (nameSplit[2] && nameSplit[2].length > 0) {
          await setFirstName(nameSplit[2]);
        }
        if (nameSplit[3] && nameSplit[3].length > 0) {
          await setPrefix(nameSplit[3]);
        }
        if (nameSplit[4] && nameSplit[4].length > 0) {
          await setSuffix(nameSplit[4]);
        }
      }

      if (element.substring(0, 9) == "NICKNAME:") {
        await setNickName(element.replace("NICKNAME:", ""));
      }

      if (element.substring(0, 16) == "ADR;TYPE=home:;;") {
        const homeAddressSplit = element
          .replace("ADR;TYPE=home:;;", "")
          .split(";");

        if (homeAddressSplit[0] && homeAddressSplit[0].length > 0) {
          await setHomeAddressStreet(homeAddressSplit[0]);
        }
        if (homeAddressSplit[1] && homeAddressSplit[1].length > 0) {
          await setHomeAddressCity(homeAddressSplit[1]);
        }
        if (homeAddressSplit[2] && homeAddressSplit[2].length > 0) {
          await setHomeAddressState(homeAddressSplit[2]);
        }
        if (homeAddressSplit[3] && homeAddressSplit[3].length > 0) {
          await setHomeAddressZip(homeAddressSplit[3]);
        }
        if (homeAddressSplit[4] && homeAddressSplit[4].length > 0) {
          await setHomeAddressCountry(homeAddressSplit[4]);
        }
      }

      if (element.substring(0, 14) == "TEL;TYPE=home:") {
        await setHomePhoneNumber(element.replace("TEL;TYPE=home:", ""));
      }

      if (element.substring(0, 14) == "TEL;TYPE=cell:") {
        await setMobilePhoneNumber(element.replace("TEL;TYPE=cell:", ""));
      }

      if (element.substring(0, 13) == "TEL;TYPE=fax:") {
        await setFaxNumber(element.replace("TEL;TYPE=fax:", ""));
      }

      if (element.substring(0, 16) == "EMAIL;TYPE=home:") {
        await setHomeEmailAddress(element.replace("EMAIL;TYPE=home:", ""));
      }

      if (element.substring(0, 16) == "ORG:") {
        const organizationSplit = element.replace("ORG:", "").split(";");

        if (organizationSplit[0] && organizationSplit[0].length > 0) {
          await setCompanyName(organizationSplit[0]);
        }
        if (organizationSplit[1] && organizationSplit[1].length > 0) {
          await setCompanyOrganization(organizationSplit[1]);
        }
        if (organizationSplit[2] && organizationSplit[2].length > 0) {
          await setCompanyOrganizationalUnit(organizationSplit[2]);
        }
      }

      if (element.substring(0, 5) == "ROLE:") {
        await setCompanyRole(element.replace("ROLE:", ""));
      }

      if (element.substring(0, 6) == "TITLE:") {
        await setCompanyTitle(element.replace("TITLE:", ""));
      }

      if (element.substring(0, 16) == "ADR;TYPE=work:;;") {
        const workAddressSplit = element
          .replace("ADR;TYPE=work:;;", "")
          .split(";");

        if (workAddressSplit[0] && workAddressSplit[0].length > 0) {
          await setWorkAddressStreet(workAddressSplit[0]);
        }
        if (workAddressSplit[1] && workAddressSplit[1].length > 0) {
          await setWorkAddressCity(workAddressSplit[1]);
        }
        if (workAddressSplit[2] && workAddressSplit[2].length > 0) {
          await setWorkAddressState(workAddressSplit[2]);
        }
        if (workAddressSplit[3] && workAddressSplit[3].length > 0) {
          await setWorkAddressState(workAddressSplit[3]);
        }
        if (workAddressSplit[4] && workAddressSplit[4].length > 0) {
          await setWorkAddressCountry(workAddressSplit[4]);
        }
      }

      if (element.substring(0, 14) == "TEL;TYPE=work:") {
        await setWorkPhoneNumber(element.replace("TEL;TYPE=work:", ""));
      }

      if (element.substring(0, 16) == "EMAIL;TYPE=work:") {
        await setWorkEmailAddress(element.replace("EMAIL;TYPE=work:", ""));
      }

      if (element.substring(0, 4) == "URL:") {
        await setWebsite(element.replace("URL:", ""));
      }

      if (element.substring(0, 11) == "CATEGORIES:") {
        await setTags(element.replace("CATEGORIES:", ""));
      }

      if (element.substring(0, 5) == "NOTE:") {
        await setNote(element.replace("NOTE:", ""));
      }
    });
  };

  const handleIincrementVisualOrderSelected = () => {
    setVisualOrder(visualOrder + 1);
  };
  const handleDecrementVisualOrderSelected = () => {
    if (visualOrder > 1) {
      setVisualOrder(visualOrder - 1);
    }
  };

  const handlePickPhotoSelected = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
      includeBase64: true,
      selectionLimit: 1,
    });

    if (!result.canceled) {
      if (result.assets[0].width > 256 || result.assets[0].height > 256) {
        // Error
        setPhotoValidationErrors(
          "Photo too large, please reduce the size and try again. Max 256 x 256"
        );
      } else {
        setPhotoValidationErrors("");
        setPhoto(result.assets[0].base64);
      }
    }
  };

  const handleClearPhotoSelected = async () => {
    await setPhoto(null);
  };

  const handlePickLogoSelected = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
      includeBase64: true,
      selectionLimit: 1,
    });

    if (!result.canceled) {
      if (result.assets[0].width > 256 || result.assets[0].height > 256) {
        // Error
        setLogoValidationErrors(
          "Logo too large, please reduce the size and try again. Max 256 x 256"
        );
      } else {
        setLogoValidationErrors("");
        setLogo(result.assets[0].base64);
      }
    }
  };

  const handleClearLogoSelected = async () => {
    await setLogo(null);
  };

  const handleDeleteSelected = () => {
    setYesNoModalTitle("Confirm Delete");
    setYesNoModalMessage(
      "Are you sure you want to delete this card configuration?"
    );
    setShowYesNoModal(true);
  };

  const handleYesNoModalResponseCallback = async (response) => {
    if (response == 1) {
      await deleteDoc(
        doc(
          fs,
          "cards/" + global.selectedCard.id + "/configurations",
          global.selectedCardConfiguration.id
        )
      );
      // Add history to card
      const historyRef = await addDoc(
        collection(fs, "cards/" + global.selectedCard.id + "/histories"),
        {
          type: "Configuration Deleted",
          metadata:
            "Configuration Deleted: " + global.selectedCardConfiguration.type,
          timestamp: new Date(),
        }
      );
      navigation.goBack();
    }

    setShowYesNoModal(false);
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <KeyboardAwareScrollView
        style={{ flex: 1, width: "100%" }}
        keyboardShouldPersistTaps="always"
      >
        <View style={{ width: "100%", flexDirection: "column" }}>
          <View
            style={{
              flexDirection: "row",
              marginLeft: 20,
              marginRight: 20,
              marginTop: 10,
            }}
          >
            <Text style={{ flex: 1 }}>Visible on Launch Page:</Text>
            <Switch
              onValueChange={toggleIsVisibleSwitch}
              value={isVisible}
              trackColor={{ true: "#69AD4C", false: "grey" }}
              activeThumbColor={"#ffffff"}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              marginRight: 20,
              marginTop: 10,
            }}
          >
            <Text style={{ flex: 1, marginLeft: 20 }}>Visual Order:</Text>
            <View
              style={{
                borderColor: "black",
                borderWidth: 1,
                flexDirection: "row",
                marginTop: 5,
              }}
            >
              <TouchableOpacity
                onPress={() => handleDecrementVisualOrderSelected()}
              >
                <Image
                  style={{
                    width: 24,
                    height: 24,
                    tintColor: "#3671C2",
                  }}
                  source={require("../../assets/material-icons/keyboard_double_arrow_down.png")}
                />
              </TouchableOpacity>
              <TextInput
                onChangeText={setVisualOrder}
                value={visualOrder}
                keyboardType="numeric"
                style={{
                  backgroundColor: "white",
                  width: 30,
                  textAlign: "center",
                }}
              ></TextInput>
              <TouchableOpacity
                onPress={() => handleIincrementVisualOrderSelected()}
              >
                <Image
                  style={{
                    width: 24,
                    height: 24,
                    tintColor: "#3671C2",
                  }}
                  source={require("../../assets/material-icons/keyboard_double_arrow_up.png")}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginLeft: 20,
              marginRight: 20,
              marginTop: 10,
            }}
          >
            <Text style={{ flex: 1 }}>Use for Anonymous Payments:</Text>
            <Switch
              onValueChange={toggleIsForAnanymousePaymentsSwitch}
              value={isForAnonymousPayments}
              trackColor={{ true: "#69AD4C", false: "grey" }}
              activeThumbColor={"#ffffff"}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              marginLeft: 20,
              marginRight: 20,
              marginTop: 10,
            }}
          >
            <Text style={{ flex: 1 }}>Use for Anonymous Payment Withdraw:</Text>
            <Switch
              onValueChange={toggleIsWithdrawSwitch}
              value={isWithdrawMethod}
              trackColor={{ true: "#69AD4C", false: "grey" }}
              activeThumbColor={"#ffffff"}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              marginLeft: 20,
              marginRight: 20,
              marginTop: 10,
            }}
          >
            <Text style={{ flex: 1 }}>Use as vCARD:</Text>
            <Switch
              onValueChange={toggleIsVCardSwitch}
              value={isVCard}
              trackColor={{ true: "#69AD4C", false: "grey" }}
              activeThumbColor={"#ffffff"}
            />
          </View>
          <View style={{ marginTop: 10, flex: 1 }}>
            <TextInput
              placeholder="Enter your message here"
              style={styles.input}
              onChangeText={(text) => setMessage(text)}
              value={message}
            />

            {!isVCard && !isForAnonymousPayments ? (
              <TextInput
                placeholder="Enter your link here ... "
                style={styles.inputMultiLine}
                onChangeText={(text) => setConfigurationMetadata(text)}
                value={configurationMetadata}
                multiline
                numberOfLines={10}
              />
            ) : null}
            {isForAnonymousPayments && global.selectedCard.paymentURL ? (
              <Text style={{ margin: 20 }}>
                Your Anonymous Payment Link is configured and ready for use.
                Your all done.
              </Text>
            ) : null}
            {isForAnonymousPayments && !global.selectedCard.paymentURL ? (
              <Text style={{ margin: 20 }}>
                Sorry, your Anonymous Payment Link isn't ready yet ... please
                hold on ... and we will email you know when it's ready. Thank
                you.
              </Text>
            ) : null}
            {isVCard ? (
              <View style={{ marginTop: 10 }}>
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {photo ? (
                    <TouchableOpacity onPress={handleClearPhotoSelected}>
                      <Image
                        source={{
                          uri: "data:image/png;base64," + photo,
                        }}
                        style={{ width: 200, height: 200, borderRadius: 40 }}
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={handlePickPhotoSelected}>
                      <Image
                        style={{ width: 200, height: 200, borderRadius: 40 }}
                        source={require("../../assets/material-icons/account_box.png")}
                      />
                    </TouchableOpacity>
                  )}
                  {photo ? (
                    <Text style={{ marginLeft: 15 }}>
                      Select to Clear Photo
                    </Text>
                  ) : (
                    <Text style={{ marginLeft: 15 }}>
                      Select to set Photo (Max 256x256)
                    </Text>
                  )}
                  {photoValidationErrors ? (
                    <Text style={{ marginLeft: 15, color: "red" }}>
                      {photoValidationErrors}
                    </Text>
                  ) : null}
                </View>

                <TextInput
                  placeholder="Enter your Prefix"
                  style={styles.input}
                  onChangeText={(text) => setPrefix(text)}
                  value={prefix}
                />
                <TextInput
                  placeholder="Enter your First Name"
                  style={styles.input}
                  onChangeText={(text) => setFirstName(text)}
                  value={firstName}
                />
                <TextInput
                  placeholder="Enter your Middle Name(s)"
                  style={styles.input}
                  onChangeText={(text) => setMiddleName(text)}
                  value={middleName}
                />
                <TextInput
                  placeholder="Enter your Last"
                  style={styles.input}
                  onChangeText={(text) => setLastName(text)}
                  value={lastName}
                />
                <TextInput
                  placeholder="Enter your Suffix"
                  style={styles.input}
                  onChangeText={(text) => setSuffix(text)}
                  value={suffix}
                />
                <TextInput
                  placeholder="Enter your Nick Name"
                  style={styles.input}
                  onChangeText={(text) => setNickName(text)}
                  value={nickName}
                />
                <TextInput
                  placeholder="Enter your Home Address Street"
                  style={styles.input}
                  onChangeText={(text) => setHomeAddressStreet(text)}
                  value={homeAddressStreet}
                />
                <TextInput
                  placeholder="Enter your Home Address City"
                  style={styles.input}
                  onChangeText={(text) => setHomeAddressCity(text)}
                  value={homeAddressCity}
                />
                <TextInput
                  placeholder="Enter your Home Address State"
                  style={styles.input}
                  onChangeText={(text) => setHomeAddressState(text)}
                  value={homeAddressState}
                />
                <TextInput
                  placeholder="Enter your Home Address Zip"
                  style={styles.input}
                  onChangeText={(text) => setHomeAddressZip(text)}
                  value={homeAddressZip}
                />
                <TextInput
                  placeholder="Enter your Home Address Country"
                  style={styles.input}
                  onChangeText={(text) => setHomeAddressCountry(text)}
                  value={homeAddressCountry}
                />
                <TextInput
                  placeholder="Enter your Home Phone Number"
                  style={styles.input}
                  onChangeText={(text) => setHomePhoneNumber(text)}
                  value={homePhoneNumber}
                />
                <TextInput
                  placeholder="Enter your Mobile Phone Number"
                  style={styles.input}
                  onChangeText={(text) => setMobilePhoneNumber(text)}
                  value={mobilePhoneNumber}
                />
                <TextInput
                  placeholder="Enter your Fax Number"
                  style={styles.input}
                  onChangeText={(text) => setFaxNumber(text)}
                  value={faxNumber}
                />
                <TextInput
                  placeholder="Enter your Home Email Address"
                  style={styles.input}
                  onChangeText={(text) => setHomeEmailAddress(text)}
                  value={homeEmailAddress}
                />

                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {logo ? (
                    <TouchableOpacity onPress={handleClearLogoSelected}>
                      <Image
                        source={{ uri: "data:image/png;base64," + logo }}
                        style={{ width: 200, height: 200, borderRadius: 40 }}
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={handlePickLogoSelected}>
                      <Image
                        style={{ width: 200, height: 200, borderRadius: 40 }}
                        source={require("../../assets/material-icons/account_box.png")}
                      />
                    </TouchableOpacity>
                  )}
                  {logo ? (
                    <Text style={{ marginLeft: 15 }}>Select to Clear Logo</Text>
                  ) : (
                    <Text style={{ marginLeft: 15 }}>
                      Select to set Logo (Max 256x256)
                    </Text>
                  )}
                  {logoValidationErrors ? (
                    <Text style={{ marginLeft: 15, color: "red" }}>
                      {logoValidationErrors}
                    </Text>
                  ) : null}
                </View>

                <TextInput
                  placeholder="Enter your Company Name"
                  style={styles.input}
                  onChangeText={(text) => setCompanyName(text)}
                  value={companyName}
                />
                <TextInput
                  placeholder="Enter your Company Organization"
                  style={styles.input}
                  onChangeText={(text) => setCompanyOrganization(text)}
                  value={companyOrganization}
                />
                <TextInput
                  placeholder="Enter your Company Organizational Unit"
                  style={styles.input}
                  onChangeText={(text) => setCompanyOrganizationalUnit(text)}
                  value={companyOrganizationalUnit}
                />
                <TextInput
                  placeholder="Enter your Company Role"
                  style={styles.input}
                  onChangeText={(text) => setCompanyRole(text)}
                  value={companyRole}
                />
                <TextInput
                  placeholder="Enter your Company Title"
                  style={styles.input}
                  onChangeText={(text) => setCompanyTitle(text)}
                  value={companyTitle}
                />
                <TextInput
                  placeholder="Enter your Work Address Street"
                  style={styles.input}
                  onChangeText={(text) => setWorkAddressStreet(text)}
                  value={workAddressStreet}
                />
                <TextInput
                  placeholder="Enter your Work Address City"
                  style={styles.input}
                  onChangeText={(text) => setWorkAddressCity(text)}
                  value={workAddressCity}
                />
                <TextInput
                  placeholder="Enter your Work Address State"
                  style={styles.input}
                  onChangeText={(text) => setWorkAddressState(text)}
                  value={workAddressState}
                />
                <TextInput
                  placeholder="Enter your Work Address Zip"
                  style={styles.input}
                  onChangeText={(text) => setWorkAddressZip(text)}
                  value={workAddressZip}
                />
                <TextInput
                  placeholder="Enter your Work Address Country"
                  style={styles.input}
                  onChangeText={(text) => setWorkAddressCountry(text)}
                  value={workAddressCountry}
                />
                <TextInput
                  placeholder="Enter your Work Phone Number"
                  style={styles.input}
                  onChangeText={(text) => setWorkPhoneNumber(text)}
                  value={workPhoneNumber}
                />
                <TextInput
                  placeholder="Enter your Work Email Address"
                  style={styles.input}
                  onChangeText={(text) => setWorkEmailAddress(text)}
                  value={workEmailAddress}
                />
                <TextInput
                  placeholder="Enter your Website"
                  style={styles.input}
                  onChangeText={(text) => setWebsite(text)}
                  value={website}
                />
                <TextInput
                  placeholder="Enter any Tags (seperated by commas)"
                  style={styles.input}
                  onChangeText={(text) => setTags(text)}
                  value={tags}
                />
                <TextInput
                  placeholder="Enter any Notes"
                  style={styles.input}
                  onChangeText={(text) => setNote(text)}
                  value={note}
                />
              </View>
            ) : null}
            {(message && configurationMetadata) ||
            (isVCard && message && (firstName != "" || lastName != "")) ||
            (isForAnonymousPayments &&
              message &&
              global.selectedCard.paymentURL != "") ? (
              <View style={{}}>
                <TouchableOpacity
                  style={styles.buttonSecondaryLaunch}
                  onPress={() => handleTestConfigurationSelected()}
                >
                  <Image
                    style={{ width: 24, height: 24, tintColor: "white" }}
                    source={require("../../assets/material-icons/ads_click.png")}
                  />
                  <Text style={styles.buttonTitleLaunch}>{message}</Text>
                </TouchableOpacity>
              </View>
            ) : null}
            <View style={{ width: "100%" }}>
              <TouchableOpacity
                style={styles.buttonPrimary}
                onPress={() => handleSaveConfigurationSelected()}
              >
                <Text style={styles.buttonTitle}>Save</Text>
              </TouchableOpacity>
              <Text style={{ margin: 10, color: "red", textAlign: "center" }}>
                {validationErrors}
              </Text>
            </View>
          </View>
        </View>
        {showYesNoModal ? (
          <YesNoModal
            title={yesNoModalTitle}
            message={yesNoModalMessage}
            responseCallback={handleYesNoModalResponseCallback}
          ></YesNoModal>
        ) : null}
      </KeyboardAwareScrollView>
    </View>
  );
}
