import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  TextInput,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import "react-confirm-alert/src/react-confirm-alert.css";
import Spinner from "react-native-loading-spinner-overlay";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const fs = getFirestore(firebaseApp);

export default function WithdrawScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const initialState = [];
  const [cardConfigurations, setCardConfigurations] =
    React.useState(initialState);
  const [isFetching, setIsFetching] = React.useState(false);

  const [withdrawAmount, setWithdrawAmount] = React.useState(
    global.selectedCard.balance
  );

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: null,
    });

    getCardConfigurations();

    setLoading(false);
  }, []);

  const getCardConfigurations = async () => {
    // Reset
    setCardConfigurations(initialState);

    // Get all the configurations
    const cardConfigurationDocs = await getDocs(
      query(
        collection(fs, "cards/" + global.selectedCard.id + "/configurations"),
        where("isVisible", "==", true),
        where("isWithdrawMethod", "==", true),
        orderBy("visualOrder", "asc")
      )
    );

    // Loop on all configurations
    cardConfigurationDocs.forEach((cardConfiguration) => {
      const configurationData = cardConfiguration.data();

      setCardConfigurations((current) => [
        ...current,
        {
          id: cardConfiguration.id,
          message: configurationData.message,
          metadata: configurationData.metadata,
          isWithdrawMethod: configurationData.isWithdrawMethod
            ? configurationData.isWithdrawMethod
            : false,
        },
      ]);
    });
  };

  const handleConfigurationSelect = async (item) => {
    // Add history to card
    const historyRef = await addDoc(
      collection(fs, "cards/" + global.selectedCard.id + "/histories"),
      {
        type: "Withdraw Request",
        metadata: "Withdraw Request: " + item.message,
        timestamp: new Date(),
      }
    );

    // Add transaction to card
    const transactionRef = await addDoc(
      collection(fs, "cards/" + global.selectedCard.id + "/transactions"),
      {
        action: "Withdraw Request",
        amount: withdrawAmount,
        metadata: item.message,
        timestamp: new Date(),
      }
    );

    // Add email to user
    const emailRef = await addDoc(collection(fs, "emails"), {
      to: [global.userEmail],
      template: {
        name: "withdraw",
        data: {
          amount: numberFormat(withdrawAmount),
          type: item.message,
        },
      },
    });

    navigation.goBack();
  };

  const numberFormat = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <View style={{ flex: 1, width: "100%" }}>
        <Text
          style={{
            marginLeft: 20,
            marginTop: 10,
            color: "#C9060C",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          ToSH: {global.selectedCard.uid}: Current Balance:{" "}
          {global.selectedCard.balance
            ? numberFormat(global.selectedCard.balance)
            : numberFormat(0)}
          {}
        </Text>
        <TextInput
          placeholder="Enter amount to withdraw"
          style={styles.input}
          onChangeText={(text) => setWithdrawAmount(text)}
          value={numberFormat(withdrawAmount)}
        />
        <FlatList
          style={{ width: "100%" }}
          data={cardConfigurations}
          keyExtractor={(cardConfigurations) => cardConfigurations.id}
          onRefresh={() => onRefresh()}
          refreshing={isFetching}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={styles.buttonSecondary}
              onPress={() => handleConfigurationSelect(item)}
            >
              <Image
                style={{ width: 24, height: 24, tintColor: "white" }}
                source={require("../../assets/material-icons/ads_click.png")}
              />

              <Text style={styles.buttonTitle}>{item.message}</Text>
            </TouchableOpacity>
          )}
        />
      </View>
    </View>
  );
}
