import React, { useState, useEffect } from "react";
import { Text, View, FlatList, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Card } from "@rneui/themed";
import Spinner from "react-native-loading-spinner-overlay";
import { format } from "date-fns";
import { Picker } from "@react-native-picker/picker";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const fs = getFirestore(firebaseApp);

export default function CardHistoriesScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const initialState = [];
  const [cardHistories, setCardHistories] = useState(initialState);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: null,
    });

    getCardHistories("");

    setLoading(false);
  }, []);

  const getCardHistories = async (filter) => {
    //Show Loading
    setLoading(true);

    // Initialize
    setCardHistories(initialState);

    let cardHistoriesDocs = undefined;
    if (filter == "" || filter == "All Histories") {
      // Get all histories
      cardHistoriesDocs = await getDocs(
        query(
          collection(fs, "cards/" + global.selectedCard.id + "/histories"),
          orderBy("timestamp", "desc")
        )
      );
    } else {
      setFilter(filter);

      // Get filtered histories
      cardHistoriesDocs = await getDocs(
        query(
          collection(fs, "cards/" + global.selectedCard.id + "/histories"),
          where("type", "==", filter),
          orderBy("timestamp", "desc")
        )
      );
    }

    cardHistoriesDocs.forEach((cardHistory) => {
      const historyData = cardHistory.data();
      setCardHistories((current) => [
        ...current,
        {
          id: cardHistory.id,
          timestamp: historyData.timestamp,
          metadata: historyData.metadata,
        },
      ]);
      // Hide Loading
      setLoading(false);
    });
    // Hide Loading
    setLoading(false);
  };

  const dateFormat = (value) => {
    return format(value, "MMMM do, yyyy H:mma");
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <Picker
        selectedValue={filter}
        onValueChange={(itemValue, itemIndex) => getCardHistories(itemValue)}
        style={{
          height: 30,
          marginTop: 10,
          width: "90%",
          marginBottom: 10,
        }}
      >
        <Picker.Item label="All Histories" value="All Histories" />
        <Picker.Item label="Registered" value="Registered" />
        <Picker.Item label="Login" value="Login" />
        <Picker.Item
          label="Configuration Updated"
          value="Configuration Updated"
        />
        <Picker.Item label="Configuration Added" value="Configuration Added" />
        <Picker.Item
          label="Configuration Deleted"
          value="Configuration Deleted"
        />
        <Picker.Item
          label="Configurations Previewed"
          value="Configurations Previewed"
        />
        <Picker.Item label="Launch Accessed" value="Launch Accessed" />
        <Picker.Item label="Launch Selected" value="Launch Selected" />
        <Picker.Item label="Payment Request" value="Payment Request" />
        <Picker.Item label="Withdraw Request" value="Withdraw Request" />
      </Picker>

      <FlatList
        style={{ width: "100%" }}
        data={cardHistories}
        keyExtractor={(cardHistories) => cardHistories.id}
        onRefresh={() => onRefresh()}
        refreshing={loading}
        renderItem={({ item }) => (
          <Card style={{ width: "90%" }}>
            <Card.Title style={{ color: "#C9060C" }}>
              {dateFormat(item.timestamp.toDate())}
            </Card.Title>
            <Card.Divider />
            <Text style={{ fontSize: 16, color: "#3671C2" }}>
              {item.metadata}{" "}
            </Text>
          </Card>
        )}
      />
    </View>
  );
}
