import React, { useState, useEffect } from "react";
import {
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Image,
  Switch,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Spinner from "react-native-loading-spinner-overlay";
import StringBuilder from "yassb";
import { isSafari } from "react-device-detect";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import { getAuth, updateProfile, updatePassword } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  getDoc,
  doc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const fs = getFirestore(firebaseApp);

export default function UserAccountScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState(global.userEmail);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validationErrors, setValidationErrors] = useState("");

  const [changePassword, setChangePassword] = useState(false);
  const toggleChangePasswordSwitch = () =>
    setChangePassword((previousState) => !previousState);

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: () => (
        <TouchableOpacity onPress={() => handleUserHistoriesSelect()}>
          <Image
            style={{
              width: 36,
              height: 36,
              tintColor: "#3671C2",
              marginRight: 10,
            }}
            source={require("../../assets/material-icons/browse_activity.png")}
          />
        </TouchableOpacity>
      ),
    });

    setFullName(global.userDisplayName);

    setLoading(false);
  }, []);

  const handleSaveAccountSelect = async () => {
    // Reset
    setValidationErrors("");
    const validationErrorsData = new StringBuilder();

    if (fullName == "") {
      validationErrorsData.addLine("Display Name is Required.");
    }

    if (email == "") {
      validationErrorsData.addLine("Email is Required.");
    }

    if (changePassword) {
      if (password == "") {
        validationErrorsData.addLine("Password is Required.");
      }
      if (password.length < 6) {
        validationErrorsData.addLine(
          "Password must be 6 or more characters long."
        );
      }

      if (confirmPassword == "") {
        validationErrorsData.addLine("Confirm Password is Required.");
      }

      if (password !== confirmPassword) {
        validationErrorsData.addLine(
          "Password and Confirm Password don't match."
        );
      }
    }

    if (validationErrorsData.toString() != "") {
      setValidationErrors(validationErrorsData.toString());
    } else {
      // Show loading
      setLoading(true);

      await updateProfile(auth.currentUser, {
        displayName: fullName,
      })
        .then(() => {
          // Do nothing ...
        })
        .catch((error) => {
          // Hide Loading
          setLoading(false);

          // Set more readable error messages, if possible
          if (error.code == "auth/invalid-display-name") {
            setValidationErrors(
              "Invalid Display Name, plese make sure that you provide a display."
            );
          } else if (error.code == "auth/email-already-exists") {
            setValidationErrors(
              "This email already has an account, please login."
            );
          } else if (error.code == "auth/invalid-email") {
            setValidationErrors(
              "This email you provied is invalude, please souble check your emal address, or try a different email address."
            );
          } else if (error.code == "auth/invalid-password") {
            setValidationErrors(
              "Your password is invalid. Please choose a different password."
            );
          } else if (error.code == "auth/user-not-found") {
            setValidationErrors(
              "Account not found. Please double check your credintials and try again."
            );
          } else {
            setValidationErrors(error.code + ": " + error.message);
          }
        });

      // Update the global variable
      global.userDisplayName = fullName;

      // Get the selected user doc
      const userDocReference = doc(fs, "/users/" + global.userId);
      const userDoc = await getDoc(userDocReference);

      // Update user with new display name, keep these in sync
      await updateDoc(userDoc.ref, { displayName: fullName }).catch((error) => {
        setValidationErrors(error.code + ": " + error.message);
      });

      if (changePassword) {
        await updatePassword(auth.currentUser, password)
          .then(() => {
            setChangePassword(false);
            setPassword("");
            setConfirmPassword("");
          })
          .catch((error) => {
            // Hide Loading
            setLoading(false);

            // Set more readable error messages, if possible
            if (error.code == "auth/invalid-display-name") {
              setValidationErrors(
                "Invalid Display Name, plese make sure that you provide a display."
              );
            } else if (error.code == "auth/email-already-exists") {
              setValidationErrors(
                "This email already has an account, please login."
              );
            } else if (error.code == "auth/invalid-email") {
              setValidationErrors(
                "This email you provied is invalude, please souble check your emal address, or try a different email address."
              );
            } else if (error.code == "auth/invalid-password") {
              setValidationErrors(
                "Your password is invalid. Please choose a different password."
              );
            } else if (error.code == "auth/user-not-found") {
              setValidationErrors(
                "Account not found. Please double check your credintials and try again."
              );
            } else {
              setValidationErrors(error.code + ": " + error.message);
            }
          });
      }

      if (validationErrors == "") {
        // Add History to the user
        const historyRef = await addDoc(
          collection(fs, "users/" + global.userId + "/histories"),
          {
            type: "Updated",
            metadata: "Account updated.",
            timestamp: new Date(),
          }
        );

        // Hide Loading
        setLoading(false);
        navigation.goBack();
      }

      // Hide Loading
      setLoading(false);
    }
  };

  const handleUserHistoriesSelect = () => {
    navigation.navigate("UserHistories");
  };

  const openDocumentURL = (url) => {
    if (url.substring(0, 7) != "http://" && url.substring(0, 8) != "https://") {
      url = "https://" + url;
    }

    const element = document.createElement("a");
    element.href = url;

    // Safari doesn't support opening new windows
    if (!isSafari) {
      element.target = "_blank";
    }
    document.body.appendChild(element);
    element.click();
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <KeyboardAwareScrollView
        style={{ flex: 1, width: "100%" }}
        keyboardShouldPersistTaps="always"
      >
        <TextInput
          style={[
            styles.input,
            { backgroundColor: "lightgrey", color: "grey" },
          ]}
          placeholder="E-mail"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => setEmail(text)}
          value={email}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          editable={false}
        />
        <Text
          style={{
            color: "#3671C2",
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          * If you need to change your email address, please ...
        </Text>
        <TouchableOpacity
          style={[styles.buttonSecondary, { marginTop: 5, marginBottom: 20 }]}
          onPress={() => openDocumentURL("https://taporscanhere.com/support")}
        >
          <Text style={styles.buttonTitle}>Contact Support</Text>
        </TouchableOpacity>
        <TextInput
          style={styles.input}
          placeholder="Display Name"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => setFullName(text)}
          value={fullName}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            marginLeft: 20,
            marginRight: 20,
            marginTop: 10,
          }}
        >
          <Text style={{ flex: 1 }}>Change Password</Text>
          <Switch
            onValueChange={toggleChangePasswordSwitch}
            value={changePassword}
            trackColor={{ true: "#69AD4C", false: "grey" }}
            activeThumbColor={"#ffffff"}
          />
        </View>

        <TextInput
          style={styles.input}
          placeholderTextColor="#aaaaaa"
          secureTextEntry
          placeholder="Password"
          onChangeText={(text) => setPassword(text)}
          value={password}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          editable={changePassword}
        />
        <TextInput
          style={styles.input}
          placeholderTextColor="#aaaaaa"
          secureTextEntry
          placeholder="Confirm Password"
          onChangeText={(text) => setConfirmPassword(text)}
          value={confirmPassword}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          editable={changePassword}
        />
        <TouchableOpacity
          style={styles.buttonPrimary}
          onPress={() => handleSaveAccountSelect()}
        >
          <Text style={styles.buttonTitle}>Update Account</Text>
        </TouchableOpacity>
        <Text style={{ margin: 10, color: "red", textAlign: "center" }}>
          {validationErrors}
        </Text>
      </KeyboardAwareScrollView>
    </View>
  );
}
