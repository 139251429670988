import React, { useState, useEffect } from "react";
import {
  Modal,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";

// props.title
// props.message
// props.responseCallback(response) // Yes = 1 / No = 0

const YesNoModal = (props) => {
  const { height, width } = useWindowDimensions();

  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    initComponentData();
  }, []);

  const initComponentData = async () => {
    setModalMessage(props.message);
    setModalTitle(props.title);
  };

  const handleModalCloseSelected = (response) => {
    props.responseCallback(response);
  };

  return (
    <View style={styles.container}>
      <Modal animationType="slide" transparent={true}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              margin: 20,
              backgroundColor: "white",
              borderRadius: 20,
              padding: 5,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 4,
              elevation: 5,
              height: height * 0.5,
              width: width * 0.75,
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginRight: 5,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    handleModalCloseSelected(0);
                  }}
                >
                  <Image
                    style={{
                      height: 32,
                      width: 32,
                      tintColor: "#3671C2",
                      marginLeft: 5,
                      marginTop: 8,
                    }}
                    source={require("../../../assets/material-icons/cancel.png")}
                  ></Image>
                </TouchableOpacity>
              </View>
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    color: "#C9060C",
                    fontSize: 24,
                    alignSelf: "center",
                  }}
                >
                  {modalTitle}
                </Text>
                <View
                  style={{
                    borderBottomColor: "lightgrey",
                    borderBottomWidth: 2,
                    marginTop: 5,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                />
              </View>

              <View
                style={{
                  marginLeft: 20,
                  marginTop: 10,
                  height: width < 400 ? height * 0.5 - 200 : height * 0.5 - 150,
                }}
              >
                <Text>{modalMessage}</Text>
              </View>
              <View
                style={{
                  fleX: 1,
                  flexDirection: width < 400 ? "column" : "row",
                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  style={{
                    marginTop: 10,
                    marginLeft: 10,
                    backgroundColor: "#69AD4C",
                    borderColor: "white",
                    borderWidth: 1,
                    marginRight: 10,
                    width: 150,
                    height: 30,
                  }}
                  onPress={() => {
                    handleModalCloseSelected(1);
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        marginTop: 6,
                        marginLeft: 5,
                      }}
                    >
                      Yes
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    marginTop: 10,
                    marginLeft: 10,
                    backgroundColor: "#3671C2",
                    borderColor: "white",
                    borderWidth: 1,
                    marginRight: 10,
                    width: 150,
                    height: 30,
                  }}
                  onPress={() => {
                    handleModalCloseSelected(0);
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        marginTop: 6,
                        marginLeft: 5,
                      }}
                    >
                      No
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default YesNoModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 5,
  },
});
