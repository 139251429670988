import React, { useState, useEffect } from "react";
import { Text, TextInput, TouchableOpacity, View, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Spinner from "react-native-loading-spinner-overlay";
import StringBuilder from "yassb";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  updateDoc,
  addDoc,
  collection,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const fs = getFirestore(firebaseApp);

export default function LoginScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  // FIXME: Reset Initial Values for Release.
  // const [email, setEmail] = useState("me@georgecain.com");
  // const [password, setPassword] = useState("Test1234!");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [validationErrors, setValidationErrors] = useState("");

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerLeft: null,
      headerRight: null,
    });
  }, []);

  const handleLoginSeleced = () => {
    // Reset
    setValidationErrors("");
    const validationErrorsData = new StringBuilder();

    if (email == "") {
      validationErrorsData.addLine("Email is Required.");
    }

    if (password == "") {
      validationErrorsData.addLine("Password is Required.");
    }

    if (validationErrorsData.toString() != "") {
      setValidationErrors(validationErrorsData.toString());
    } else {
      // Show Logging In
      setLoading(true);

      signInWithEmailAndPassword(auth, email.trim(), password)
        .then(async (userCredential) => {
          // Make sure the email is verified.
          if (!auth.currentUser.emailVerified) {
            // Not verified, but give then 14 days to get verified
            const msDiff =
              new Date().getTime() - auth.currentUser.metadata.createdAt;
            const daysSinceRegistration = Math.floor(
              msDiff / (1000 * 60 * 60 * 24)
            );
            global.userDaysSinceRegistration = daysSinceRegistration;
          }

          // Successfully verified and logged in, save user, navigate to card list
          global.authUserUID = userCredential.user.uid;
          global.userEmail = email;
          global.userDisplayName = userCredential.user.displayName
            ? userCredential.user.displayName
            : "";

          // Get a reference to the users collection
          const usersRefs = collection(fs, "users");

          // Query for the user uid
          const userQuery = query(
            usersRefs,
            where("authUserUID", "==", global.authUserUID)
          );
          const userDocs = await getDocs(userQuery);

          // Loop on all the user's found
          userDocs.forEach(async (user) => {
            global.userId = user.id;
          });

          // Add History to the user
          const userHistoryDoc = await addDoc(
            collection(fs, "users/" + global.userId + "/histories"),
            {
              type: "Logged In",
              metadata: "Account logged in.",
              timestamp: new Date(),
            }
          );

          if (global.cardData && global.cardData.uid != "") {
            // Get a reference to the card
            const cardDocumentReference = doc(fs, "cards", global.cardId);
            const cardDocumentSnapshot = await getDoc(cardDocumentReference);

            if (
              cardDocumentSnapshot.exists() &&
              (!cardDocumentSnapshot.data().authUserUID ||
                cardDocumentSnapshot.data().authUserUID == "")
            ) {
              // Update card with existing user id
              await updateDoc(cardDocumentReference, {
                authUserUID: authUserUID,
              });

              // Add history to card
              const historyRef = await addDoc(
                collection(fs, "cards/" + global.cardId + "/histories"),
                {
                  type: "Registered",
                  metadata: "Registered",
                  timestamp: new Date(),
                }
              );
            } else {
              // Add history to card
              const historyRef = await addDoc(
                collection(fs, "cards/" + global.cardId + "/histories"),
                { type: "Login", metadata: "Login", timestamp: new Date() }
              );
            }

            // Clear the form
            setEmail("");
            setPassword("");

            // Hide Logging In
            setLoading(false);

            navigation.navigate("Home");
          } else {
            // Clear the form
            setEmail("");
            setPassword("");

            // Hide Logging In
            setLoading(false);

            navigation.navigate("Home");
          }
        })
        .catch((error) => {
          // Hide Loading
          setLoading(false);

          // Set more readable error messages, if possible
          if (error.code == "auth/invalid-display-name") {
            setValidationErrors(
              "Invalid Display Name, plese make sure that you provide a display."
            );
          } else if (error.code == "auth/email-already-exists") {
            setValidationErrors(
              "This email already has an account, please login."
            );
          } else if (error.code == "auth/invalid-email") {
            setValidationErrors(
              "This email you provied is invalude, please souble check your emal address, or try a different email address."
            );
          } else if (error.code == "auth/invalid-password") {
            setValidationErrors(
              "Your password is invalid. Please choose a different password."
            );
          } else if (error.code == "auth/user-not-found") {
            setValidationErrors(
              "Account not found. Please double check your credintials and try again."
            );
          } else if (error.code == "auth/wrong-password") {
            setValidationErrors("Incorrect credentials. Please try again.");
          } else {
            setValidationErrors(error.code + ": " + error.message);
          }
        });
    }
  };

  const handleForgotPasswordSelected = () => {
    navigation.navigate("ResetPassword");
  };

  const handleLoginWithGoogleSelected = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;

        //TODO: What todo now? Auth Adds User if they Don't exist,
        //  need to associate cards, setup Users in Firestore.
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <KeyboardAwareScrollView
        style={{ flex: 1, width: "100%" }}
        keyboardShouldPersistTaps="always"
      >
        <Text style={{ marginTop: 10, marginBottom: 10, marginLeft: 15 }}>
          Enter your email and password to login:
        </Text>
        <TextInput
          style={styles.input}
          placeholder="E-mail"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => setEmail(text)}
          value={email}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholderTextColor="#aaaaaa"
          secureTextEntry
          placeholder="Password"
          onChangeText={(text) => setPassword(text)}
          value={password}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          onSubmitEditing={() => handleLoginSeleced()}
        />
        <TouchableOpacity
          style={{}}
          onPress={() => handleForgotPasswordSelected()}
        >
          <Text style={{ textAlign: "center", color: "#69AD4C" }}>
            Forgot Password?
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.buttonSecondary}
          onPress={() => handleLoginSeleced()}
        >
          <Text style={styles.buttonTitle}>Login</Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
          style={styles.button}
          onPress={() => handleLoginWithGoogleSelected()}
        >
          <Text style={styles.buttonTitle}>Login with Google</Text>
        </TouchableOpacity> */}
        <Text style={{ margin: 10, color: "red", textAlign: "center" }}>
          {validationErrors}
        </Text>
      </KeyboardAwareScrollView>
      <Text style={{ marginBottom: 10 }}>version: {global.versionNumber}</Text>
    </View>
  );
}
