import React, { useEffect, useState } from "react";
import { Text, TextInput, TouchableOpacity, View, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Spinner from "react-native-loading-spinner-overlay";
import StringBuilder from "yassb";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  GoogleAuthProvider,
  getRedirectResult,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const fs = getFirestore(firebaseApp);

export default function RegistrationScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validationErrors, setValidationErrors] = useState("");

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: null,
    });

    setLoading(false);
  }, []);

  const handleFooterLinkSelected = () => {
    navigation.navigate("Login");
  };

  const handleRegisterSelected = () => {
    setLoading(true);

    // Reset
    setValidationErrors("");
    const validationErrorsData = new StringBuilder();

    if (fullName == "") {
      validationErrorsData.addLine("Display Name is Required.");
    }

    if (email == "") {
      validationErrorsData.addLine("Email is Required.");
    }

    if (password == "") {
      validationErrorsData.addLine("Password is Required.");
    }
    if (password.length < 6) {
      validationErrorsData.addLine(
        "Password must be 6 or more characters long."
      );
    }

    if (confirmPassword == "") {
      validationErrorsData.addLine("Confirm Password is Required.");
    }

    if (password !== confirmPassword) {
      validationErrorsData.addLine(
        "Password and Confirm Password don't match."
      );
    }

    if (validationErrorsData.toString() != "") {
      setValidationErrors(validationErrorsData.toString());
    } else {
      // Show loading
      setLoading(true);

      if (global.cardData && global.cardData.uid != "") {
        createUserWithEmailAndPassword(auth, email.trim(), password)
          .then(async (userCredential) => {
            // Signed in
            global.authUserUID = userCredential.user.uid;
            global.userEmail = email;
            global.userDisplayName = fullName;

            // Send the user an email verification email
            sendEmailVerification(auth.currentUser).then(() => {});

            // Save User to firestore
            const userDoc = await addDoc(collection(fs, "users"), {
              authUserUID: global.authUserUID,
              email: email,
              displayName: fullName,
            });

            global.userId = userDoc.id;

            // Add History to the user
            const userHistoryDoc = await addDoc(
              collection(fs, "users/" + global.userId + "/histories"),
              {
                type: "Registered",
                metadata: "Account registered.",
                timestamp: new Date(),
              }
            );

            // Get a reference to the card
            const cardDoc = doc(fs, "cards", global.cardId);

            // Update card with new auth user uid
            await updateDoc(cardDoc, { authUserUID: global.authUserUID });

            // Add History to card
            const historyRef = await addDoc(
              collection(fs, "cards/" + global.cardId + "/histories"),
              {
                type: "Registered",
                metadata: "Registered",
                timestamp: new Date(),
              }
            );

            // Send registration email to user.
            const emailRef = await addDoc(collection(fs, "emails"), {
              to: [global.userEmail],
              template: {
                name: "registration",
                data: {
                  tosh: global.cardData.uid,
                },
              },
            });

            // Hide Loading
            setLoading(false);
            navigation.navigate("Home");
          })
          .catch((error) => {
            // Hide Loading
            setLoading(false);

            // Set more readable error messages, if possible
            if (error.code == "auth/invalid-display-name") {
              setValidationErrors(
                "Invalid Display Name, plese make sure that you provide a valid display name."
              );
            } else if (error.code == "auth/email-already-exists") {
              setValidationErrors(
                "This email already has an account, please login."
              );
            } else if (error.code == "auth/invalid-email") {
              setValidationErrors(
                "The email you provided is invalid, please double check your email address, or try a different email address."
              );
            } else if (error.code == "auth/invalid-password") {
              setValidationErrors(
                "Your password is invalid. Please choose a different password."
              );
            } else if (error.code == "auth/user-not-found") {
              setValidationErrors(
                "Account not found. Please double check your credintials and try again."
              );
            } else {
              setValidationErrors(error.code + ": " + error.message);
            }
          });
      }
      // Hide Loading
      setLoading(false);
    }
  };

  const handleRegisterWithGoogleSelected = () => {
    getRedirectResult(auth)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <KeyboardAwareScrollView
        style={{ flex: 1, width: "100%" }}
        keyboardShouldPersistTaps="always"
      >
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={{}}>
            This card hasn't been registered. Please enter your information
            below to register. If you already have an account,
            <Text
              onPress={handleFooterLinkSelected}
              style={{ color: "#3671C2", fontWeight: "bold" }}
            >
              {" "}
              Log in
            </Text>
          </Text>
        </View>
        <TextInput
          style={styles.input}
          placeholder="Display Name"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => setFullName(text)}
          value={fullName}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholder="E-mail"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => setEmail(text)}
          value={email}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholderTextColor="#aaaaaa"
          secureTextEntry
          placeholder="Password"
          onChangeText={(text) => setPassword(text)}
          value={password}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholderTextColor="#aaaaaa"
          secureTextEntry
          placeholder="Confirm Password"
          onChangeText={(text) => setConfirmPassword(text)}
          value={confirmPassword}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          onSubmitEditing={() => handleRegisterSelected()}
        />
        <TouchableOpacity
          style={styles.buttonPrimary}
          onPress={() => handleRegisterSelected()}
        >
          <Text style={styles.buttonTitle}>Create Account</Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
          style={styles.button}
          onPress={() => handleRegisterWithGoogleSelected()}
        >
          <Text style={styles.buttonTitle}>Register with Google</Text>
        </TouchableOpacity> */}
        <Text style={{ margin: 10, color: "red", textAlign: "center" }}>
          {validationErrors}
        </Text>
      </KeyboardAwareScrollView>
    </View>
  );
}
