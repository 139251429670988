import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import SplashScreen from "./src/screens/SplashScreen";
import LoginScreen from "./src/screens/LoginScreen";
import ResetPasswordScreen from "./src/screens/ResetPasswordScreen";
import RegistrationScreen from "./src/screens/RegistrationScreen";
import UserAccountScreen from "./src/screens/UserAccountScreen";

import LaunchScreen from "./src/screens/LaunchScreen";

import HomeScreen from "./src/screens/HomeScreen";
import ConfigurationsScreen from "./src/screens/ConfigurationsScreen";
import ConfigurationDetailsScreen from "./src/screens/ConfigurationDetailsScreen";
import TransactionsScreen from "./src/screens/TransactionsScreen";
import WithdrawScreen from "./src/screens/WithdrawScreen";
import CardHistoriesScreen from "./src/screens/CardHistoriesScreen";

import ErrorScreen from "./src/screens/ErrorScreen";

import "react-native-gesture-handler";
import { decode, encode } from "base-64";
import UserHistoriesScreen from "./src/screens/UserHistoriesScreen";

if (!global.btoa) {
  global.btoa = encode;
}
if (!global.atob) {
  global.atob = decode;
}

const Stack = createStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName="SplashScreen"
        mode="card"
        screenOptions={{ cardStyle: { flex: 1 } }}
      >
        <Stack.Screen
          name="SplashScreen"
          component={SplashScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen name="Login" component={LoginScreen} options={{}} />
        <Stack.Screen
          name="ResetPassword"
          component={ResetPasswordScreen}
          options={{}}
        />
        <Stack.Screen
          name="Registration"
          component={RegistrationScreen}
          options={{ headerLeft: null }}
        />
        <Stack.Screen
          name="Account"
          component={UserAccountScreen}
          options={{}}
        />
        <Stack.Screen name="UserHistories" component={UserHistoriesScreen} />
        <Stack.Screen name="Launch" component={LaunchScreen} options={{}} />
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{ headerLeft: null }}
        />
        <Stack.Screen name="Configurations" component={ConfigurationsScreen} />
        <Stack.Screen
          name="Configuration Details"
          component={ConfigurationDetailsScreen}
        />
        <Stack.Screen name="Transactions" component={TransactionsScreen} />
        <Stack.Screen name="Withdraw" component={WithdrawScreen} />
        <Stack.Screen name="CardHistories" component={CardHistoriesScreen} />
        <Stack.Screen
          name="Error"
          component={ErrorScreen}
          options={{ headerLeft: null }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
