import React, { useState, useEffect } from "react";
import { Text, View, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Spinner from "react-native-loading-spinner-overlay";

import styles from "../common/styles";

export default function ErrorScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: null,
    });

    setLoading(false);
  }, []);

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <Text style={{ margin: 19, color: "#C9060C" }}>
        Ooops, something went wrong.
      </Text>
      <Text style={{ margin: 19, color: "#C9060C" }}>
        Please try again later.
      </Text>
      <Text style={{ margin: 19, color: "#C9060C" }}>
        {global.errorCode}: {global.errorMessage}
      </Text>
    </View>
  );
}
