import React, { useState, useEffect } from "react";
import { Text, TextInput, TouchableOpacity, View, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Spinner from "react-native-loading-spinner-overlay";
import StringBuilder from "yassb";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export default function ResetPasswordScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  // FIXME: Reset Initial Values for Release.
  // const [email, setEmail] = useState("me@georgecain.com");
  // const [password, setPassword] = useState("Test12345");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [validationErrors, setValidationErrors] = useState("");

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: null,
    });

    setLoading(false);
  }, []);

  const handleResetPasswordSubmitSelected = () => {
    // Reset
    setValidationErrors("");

    // Show loading
    setLoading(true);

    const validationErrorsData = new StringBuilder();

    if (email == "") {
      validationErrorsData.addLine("Email is Required.");
    }

    if (validationErrorsData.toString() != "") {
      setValidationErrors(validationErrorsData.toString());
      // Hide loading
      setLoading(false);
    } else {
      // Send
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setValidationErrors(
            "Rest Password email sent. Please check your email. Once reset, go back to the login screen to access your account."
          );
          // Hide loading
          setLoading(false);
        })
        .catch((error) => {
          //setValidationErrors(error.code + ": " + error.message);
          setValidationErrors(
            "Rest Password email sent. Please check your email. Once reset, go back to the login screen to access your account."
          );
          // Hide loading
          setLoading(false);
        });
    }
    // Hide loading
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <KeyboardAwareScrollView
        style={{ flex: 1, width: "100%" }}
        keyboardShouldPersistTaps="always"
      >
        <Text style={{ margin: 20 }}>
          Enter your email address to receive a reset password email.
        </Text>
        <TextInput
          style={styles.input}
          placeholder="E-mail"
          placeholderTextColor="#aaaaaa"
          onChangeText={(text) => setEmail(text)}
          value={email}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
        />

        <TouchableOpacity
          style={styles.buttonPrimary}
          onPress={() => handleResetPasswordSubmitSelected()}
        >
          <Text style={styles.buttonTitle}>Submit</Text>
        </TouchableOpacity>
        <Text style={{ margin: 10, color: "red", textAlign: "center" }}>
          {validationErrors}
        </Text>
      </KeyboardAwareScrollView>
    </View>
  );
}
