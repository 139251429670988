import React, { useState, useEffect } from "react";
import { Text, View, FlatList, Image, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Card } from "@rneui/themed";
import Spinner from "react-native-loading-spinner-overlay";
import { format } from "date-fns";
import { Picker } from "@react-native-picker/picker";

import styles from "../common/styles";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlZIH4uxwLZM0o-t34qBQ2LuzGCDVuVMI",
  authDomain: "taporscanheredotcom.firebaseapp.com",
  projectId: "taporscanheredotcom",
  storageBucket: "taporscanheredotcom.appspot.com",
  messagingSenderId: "1062398182615",
  appId: "1:1062398182615:web:05643b160a56b6f8335c92",
  measurementId: "G-JRLL8ZRS9F",
};

const firebaseApp = initializeApp(firebaseConfig);
const fs = getFirestore(firebaseApp);

export default function TransactionsScreen({}) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const initialState = [];
  const [cardTransactions, setCardTransactions] = useState(initialState);

  const [filter, setFilter] = useState("");

  useEffect(() => {
    setLoading(true);

    navigation.setOptions({
      headerTitle: () => (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ height: 48, width: 250 }}
            resizeMode="contain"
            source={require("../../assets/logos/ToSH-App-Logo-Flat.png")}
          />
        </View>
      ),
      headerTitleAlign: "center",
      headerRight: () => (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginRight: 10,
          }}
        >
          {global.selectedCard.balance > 0 ? (
            <TouchableOpacity onPress={() => handleWithdrawSelect()}>
              <Image
                style={{
                  width: 36,
                  height: 36,
                  tintColor: "#69AD4C",
                }}
                source={require("../../assets/material-icons/currency_exchange.png")}
              />
            </TouchableOpacity>
          ) : null}
        </View>
      ),
    });

    navigation.addListener("focus", () => {
      getCardTransactions("All Transactions");
    });

    setLoading(false);
  }, []);

  const getCardTransactions = async (filter) => {
    //Show Loading
    setLoading(true);

    // Initialize
    setCardTransactions(initialState);

    let cardTransactionDocs = undefined;
    if (filter == "" || filter == "All Transactions") {
      // Get all the histories
      cardTransactionDocs = await getDocs(
        query(
          collection(fs, "cards/" + global.selectedCard.id + "/transactions"),
          orderBy("timestamp", "desc")
        )
      );
    } else {
      setFilter(filter);

      // Get filtered transactions
      cardTransactionDocs = await getDocs(
        query(
          collection(fs, "cards/" + global.selectedCard.id + "/transactions"),
          where("action", "==", filter),
          orderBy("timestamp", "desc")
        )
      );
    }

    // Loop on each transaction found
    cardTransactionDocs.forEach((cardTransaction) => {
      const transactionData = cardTransaction.data();
      setCardTransactions((current) => [
        ...current,
        {
          id: cardTransaction.id,
          timestamp: transactionData.timestamp,
          amount: transactionData.amount,
          action: transactionData.action,
          metadata: transactionData.metadata,
        },
      ]);
      // Hide Loading
      setLoading(false);
    });
    // Hide Loading
    setLoading(false);
  };

  const numberFormat = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const dateFormat = (value) => {
    return format(value, "MMMM do, yyyy H:mma");
  };

  const handleWithdrawSelect = () => {
    navigation.navigate("Withdraw");
  };

  return (
    <View style={styles.container}>
      <Spinner visible={loading} textContent={""} color="#C9060C" size={64} />
      <View
        style={{
          flexDirection: "row",
          marginTop: 10,
          width: "100%",
        }}
      >
        <Text
          style={{
            flex: 1,
            marginLeft: 20,
            color: "#C9060C",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          ToSH: {global.selectedCard.uid}: Current Balance:{" "}
          {global.selectedCard.balance
            ? numberFormat(global.selectedCard.balance)
            : numberFormat(0)}
          {}
        </Text>
      </View>
      <Picker
        selectedValue={filter}
        onValueChange={(itemValue, itemIndex) => getCardTransactions(itemValue)}
        style={{
          height: 30,
          marginTop: 10,
          width: "90%",
          marginBottom: 10,
        }}
      >
        <Picker.Item label="All Transactions" value="All Transactions" />
        <Picker.Item label="Payment Request" value="Payment Request" />
        <Picker.Item label="Payment" value="Payment" />
        <Picker.Item label="Withdraw Request" value="Withdraw Request" />
        <Picker.Item label="Withdraw" value="Withdraw" />
      </Picker>
      <FlatList
        style={{ width: "100%" }}
        data={cardTransactions}
        keyExtractor={(cardTransactions) => cardTransactions.id}
        onRefresh={() => onRefresh()}
        refreshing={loading}
        renderItem={({ item }) => (
          <Card style={{ width: "90%" }}>
            <Card.Title style={{ color: "#C9060C" }}>
              {dateFormat(item.timestamp.toDate())}
            </Card.Title>
            <Card.Divider />
            <View style={{ flex: 1, flexDirection: "row", margin: 5 }}>
              <View style={{ flex: 1, flexDirection: "row" }}>
                {item.action == "Payment" ||
                item.action == "Payment Request" ? (
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 10,
                      tintColor: "#3671C2",
                    }}
                    source={require("../../assets/material-icons/paid.png")}
                  />
                ) : (
                  <Image
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 10,
                      tintColor: "#3671C2",
                    }}
                    source={require("../../assets/material-icons/currency_exchange.png")}
                  />
                )}
                <Text style={{ fontSize: 16, color: "#3671C2" }}>
                  {item.action}
                  {item.action == "Withdraw Request" ? (
                    <Text style={{ fontSize: 16 }}> - {item.metadata} </Text>
                  ) : null}
                </Text>
              </View>
              {item.amount ? (
                <Text
                  style={{
                    fontSize: 16,
                    alignSelf: "flex-end",
                    color: "#3671C2",
                  }}
                >
                  {numberFormat(item.amount)}{" "}
                </Text>
              ) : null}
            </View>
          </Card>
        )}
      />
    </View>
  );
}
